import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "@bounce/shared/requests";
import {PaymentCardDetails} from "../../../declerations/server";
import {IResponseData} from "../../../pages/account/payment/utils";

type Variables = {
  token: string;
  amount: number;
  creditCard: PaymentCardDetails;
};

type Data = IResponseData & {external_payment_ids: string[]};

export const makeGuestPayment = async (variables: Variables): Promise<Data> => {
  const result = await axiosConsumerAppInstance.post<Data, AxiosResponse<Data>>(`/payments/guest/${variables.token}`, {
    amount: variables.amount,
    credit_card: variables.creditCard,
  });
  return result.data;
};

export const useMakeGuestPayment = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => {
  return useBaseMutation(makeGuestPayment, {
    ...options,
  });
};
