import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "@bounce/shared/requests";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "@bounce/shared/requests";
import {PaymentPlanOffers} from "../../../declerations/server/payment_plan_models";
import {useUser} from "../../../contexts";

type Variables = {
  account_id: string;
  isAgent?: boolean;
};

type Data = PaymentPlanOffers;

export const getPaymentPlanOffersByAccount = async (variables: Variables): Promise<Data> => {
  if (variables.isAgent || !variables.account_id) {
    // @ts-ignore
    return;
  }
  const result = await axiosConsumerAppInstance.get<Data, AxiosResponse<Data>>(
    `/payment_plans/get-payment-plan-offers-by-account/${variables.account_id}`
  );

  return result.data;
};

export const useGetPaymentPlanOffersByAccount = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => useBaseMutation(getPaymentPlanOffersByAccount, options);

export const useGetPaymentPlanOffersByAccountQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  const {isAgent} = useUser();
  return useBaseQuery<Data, Variables>(
    ["account", variables.account_id, "plan-offers"],
    getPaymentPlanOffersByAccount,
    {isAgent, ...variables},
    options
  );
};
