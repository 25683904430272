import {ReactNode} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useUserMetaData} from "../../contexts";
import {urls} from "routes/urls";
import {useGetAccount} from "../../apis/requests/user/getAccount";

type AuthGuardProps = {
  children: ReactNode;
};

const AuthGuard = ({children}: AuthGuardProps) => {
  const {loggedIn, isAgent, accountId} = useUserMetaData();
  const {pathname, search} = useLocation();
  const queryParams = new URLSearchParams(search);
  const userId = queryParams.get("id");
  const {data: account} = useGetAccount({accountId}, {enabled: !!(loggedIn && !isAgent && accountId)});
  const inCreditorRecalled = account?.status === "creditor_recalled";
  const accountClosed = account?.status === "closed";

  if (search && !loggedIn && userId) {
    return <Navigate to={urls.login.url + search} state={{origin: pathname + search}} />;
  } else if (!loggedIn) {
    return <Navigate to={urls.login.url} state={{origin: pathname}} />;
  }
  if (inCreditorRecalled || accountClosed) {
    return <Navigate to={urls.accounts.url + search} state={{origin: pathname + search}} />;
  }
  return <>{children}</>;
};

export default AuthGuard;
