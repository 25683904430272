import {ReactNode} from "react";
import {useUi} from "../../contexts";
import {LoadingCenter} from "@bounce/shared/ui";

type UiGuardProps = {
  children: ReactNode;
};

const UiGuard = ({children}: UiGuardProps) => {
  const {isLoading} = useUi();

  if (isLoading) {
    return <LoadingCenter />;
  }

  return <>{children}</>;
};

export default UiGuard;
