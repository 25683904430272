import {axiosSpringInstance} from "../../../ServerApiManager";
import {AxiosResponse} from "axios";

import {getViewMode} from "@bounce/shared/helpers";

type Variables = {
  lenderFileId: string;
  fileName?: string;
  viewMode?: boolean;
};

type DownloadLink = {
  url: string;
  file_name: string;
};
export const downloadLenderFile = async (variables: Variables): Promise<void> => {
  const viewMode = getViewMode(variables.fileName, variables.viewMode);
  variables.viewMode = viewMode;

  const result = await axiosSpringInstance.get<DownloadLink, AxiosResponse<DownloadLink>>(
    `/admin/lender-files/download-link/${variables.lenderFileId}`,
    {params: {view_mode: variables.viewMode}}
  );
  const link = document.createElement("a");
  link.href = result.data.url;
  link.download = result.data.file_name;
  if (viewMode) {
    link.target = "_blank";
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
