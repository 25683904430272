import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "@bounce/shared/requests";

type Variables = {
  installmentId: string;
  dueDate: Date;
};
type Data = string;

export const updateInstallmentDate = async (variables: Variables): Promise<Data> => {
  const result = await axiosFlexInstance.patch<string, AxiosResponse<string>>(
    `/installments/${variables.installmentId}`,
    {
      due_date: variables.dueDate,
    }
  );
  return result.data;
};

export const useUpdateInstallmentDate = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  // TODO: ["account", account_id, "dashboard"]
  useBaseMutation(updateInstallmentDate, {
    invalidateQueriesKeyFn: (data, variables) => ["account"],
    ...options,
  });
