import React, {ReactNode} from "react";
import {Colors} from "../../colors";
import {Container} from "../Container";
import {Text} from "../Text";
import {Divider} from "../Divider";

interface ITimeline {
  icon?: ReactNode;
  date?: string;
  children?: ReactNode;
}

export const TimelineItem = ({icon, date, children}: ITimeline) => {
  return (
    <Container fullWidth row sx={{alignItems: "end", gap: "16px"}}>
      {icon && (
        <Container sx={{height: "100%", gap: "0px"}}>
          <Divider sx={{width: "2px", height: "34%"}} />
          <Container sx={{width: "54px", height: "54px"}}>{icon}</Container>
          <Divider sx={{width: "2px", height: "100%"}} />
        </Container>
      )}
      {date && (
        <Container sx={{paddingTop: "32px", marginLeft: "-10px"}}>
          <Text
            size={11}
            fontWeight={"medium"}
            sx={{
              backgroundColor: Colors.bw.white,
              boxShadow: (theme) => theme.customShadows.cardV2,
              padding: "1px 10px",
              borderRadius: "12px",
              whiteSpace: "nowrap",
              textTransform: "uppercase",
            }}
          >
            {date}
          </Text>
        </Container>
      )}
      {children}
    </Container>
  );
};
