export enum InteractionType {
  EMAIL_SENT = "EMAIL_SENT",
  EMAIL_RECEIVED = "EMAIL_RECEIVED",
  PHONE_CALL_INCOMING_ANSWERED = "PHONE_CALL_INCOMING_ANSWERED",
  PHONE_CALL_INCOMING_NOT_ANSWERED = "PHONE_CALL_INCOMING_NOT_ANSWERED",
  OUTBOUND_PHONE_CALL_ANSWERED = "OUTBOUND_PHONE_CALL_ANSWERED",
  PHONE_CALL_INCOMING_ASSIGNED_TO_RESOLUTION_EXPERT = "PHONE_CALL_INCOMING_ASSIGNED_TO_RESOLUTION_EXPERT",
  OUTBOUND_CALL_DIALED = "OUTBOUND_CALL_DIALED",
  ARBITRARY_CALL_DIALED = "ARBITRARY_CALL_DIALED",
  SMS_INCOMING = "SMS_INCOMING",
  SMS_QUEUED = "SMS_QUEUED",
  SMS_SENT = "SMS_SENT",
  SMS_OPT_OUT = "SMS_OPT_OUT",
  WEBCHAT_COMPLETED = "WEBCHAT_COMPLETED",
  ACCOUNT_UPDATE = "ACCOUNT_UPDATE",
  DISPUTE_FILED = "DISPUTE_FILED",
  PAYMENT_PLAN_SELECTED = "PAYMENT_PLAN_SELECTED",
  PAYMENT_PLAN_DEACTIVATED = "PAYMENT_PLAN_DEACTIVATED",
  PAYMENT_PLAN_FREEZE_PAYMENT = "PAYMENT_PLAN_FREEZE_PAYMENT",
  LETTER_QUEUED = "LETTER_QUEUED",
  CONTACT_US = "CONTACT_US",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  PAYMENT_FAILURE = "PAYMENT_FAILURE",
  PHONE_CALL_SUMMARY_RECEIVED = "PHONE_CALL_SUMMARY_RECEIVED",
  TIMED_ARBITRARY_COMMUNICATION = "TIMED_ARBITRARY_COMMUNICATION",
  COLLECTION_NOTICE_TRIGGER = "COLLECTION_NOTICE_TRIGGER",
}
