import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "@bounce/shared/requests";
import {UserExternal} from "../../../declerations/server/user_models";
import {useUser} from "../../../contexts";

type Variables = {
  userId?: string;
  accountId?: string;
  isAgent?: boolean;
};

type Data = UserExternal;
export const getUserWithSelectedAccount = async (variables: Variables): Promise<Data> => {
  if (!variables.userId || !variables.isAgent) {
    // @ts-ignore
    return;
  }
  const result = await axiosFlexInstance.get<Data, AxiosResponse<Data>>(`/get_user_details/${variables.userId}`, {
    params: {...(variables.accountId && {account_id: variables.accountId})},
  });
  return result.data;
};

export const useGetUserWithSelectedAccountQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  const {isAgent} = useUser();
  const queryKey = variables.userId ? ["user", "data", variables.userId] : ["invalid-user"];
  return useBaseQuery<Data, Variables>(queryKey, getUserWithSelectedAccount, {...variables, isAgent}, options);
};
