type ChannelType =
  | "phone_call"
  | "sms"
  | "email"
  | "letter"
  | "webchat"
  | "webchat_offline"
  | "payment_plan"
  | "payment"
  | "account_update"
  | "contact_us"
  | "dispute"
  | "user_activity"
  | "trigger"
  | "comment";

type EventType =
  | "PHONE_CALL_INCOMING"
  | "PHONE_CALL_MISSED_INCOMING"
  | "PHONE_CALL_DIALED"
  | "PHONE_CALL_ANSWERED"
  | "SCHEDULED_PHONE_CALL"
  | "SMS_RECEIVED"
  | "SMS_SENT"
  | "SMS_DELIVERED"
  | "SMS_UNDELIVERED"
  | "SMS_LINK_CLICK"
  | "SMS_PAGE_CLICK"
  | "SMS_OPT_OUT"
  | "SMS_QUEUED"
  | "SCHEDULED_SMS"
  | "EMAIL_RECEIVED"
  | "EMAIL_SENT"
  | "EMAIL_DELIVERED"
  | "EMAIL_OPEN"
  | "EMAIL_CLICK"
  | "EMAIL_UNSUBSCRIBE"
  | "SCHEDULED_EMAIL"
  | "LETTER"
  | "WEBCHAT_CHAT"
  | "WEBCHAT_OFFLINE"
  | "PAYMENT_PLAN_SELECTED"
  | "PAYMENT_PLAN_DEACTIVATED"
  | "PAYMENT_RECEIVED"
  | "PAYMENT_FAILED"
  | "PAYMENT_SUBMITTED"
  | "PAYMENT_FAILED_RETRY"
  | "PAYMENT_PENDING"
  | "PAYMENT_CHARGEBACK"
  | "ACCOUNT_UPDATE_ADD"
  | "ACCOUNT_UPDATE_DELETE"
  | "ACCOUNT_UPDATE"
  | "CONTACT_US"
  | "DISPUTE_FILED"
  | "USER_POSTPONED_CREDIT_REPORT"
  | "USER_VISIT_PAYMENT_PLAN_SUMMARY"
  | "USER_VISIT_PAYMENT_PLANS"
  | "COLLECTION_NOTICE_TRIGGER"
  | "UNKNOWN"
  | "INSTALLMENT_DUE_DATE_UPDATED"
  | "COMMENT"
  | "LOG_IN"
  | "VALIDATION_IN_APP";

enum AccountUpdateAction {
  ADD = "add",
  DELETE = "delete",
  UPDATE = "update",
}

enum CommunicationDirection {
  OUTBOUND = "OUTBOUND",
  INBOUND = "INBOUND",
}

enum CommunicateBy {
  AGENT = "Agent",
  USER = "User",
  SYSTEM = "System",
}

export type {ChannelType, EventType};
export {AccountUpdateAction, CommunicationDirection, CommunicateBy};
