import * as React from "react";
import {DataGridProps, GridRowSelectionModel} from "@mui/x-data-grid";
import {DataGridStyled} from "./style";
import {Colors} from "../../colors";

type IDataTable = DataGridProps & {
  selected?: GridRowSelectionModel;
  setSelected?: (selected: GridRowSelectionModel) => void;
};

export const DEFAULT_PAGE_SIZE = 10;

export const DataGrid = ({selected, setSelected, ...props}: IDataTable) => {
  const handleCellClick = (params: any) => {
    if (setSelected && (!params.field || params.field === "_check_")) {
      setSelected(params.rowIds);
    }
  };
  return (
    <DataGridStyled
      onRowSelectionModelChange={(newSelectionModel: GridRowSelectionModel) => {
        if (setSelected) setSelected(newSelectionModel);
      }}
      rowSelectionModel={selected}
      onCellClick={handleCellClick}
      disableRowSelectionOnClick
      sx={{border: `1px solid ${Colors.tertiary._300}`, backgroundColor: Colors.bw.white, borderRadius: "12px"}}
      {...props}
    />
  );
};
