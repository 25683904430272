import dayjs from "dayjs";

export const ISO_FORMAT = "YYYY-MM-DD[T]HH:mm:ss";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_V2 = "MMM DD, YYYY";
export const DATE_FORMAT_V3 = "MM/DD/YYYY";

export const TIMESTAMP_FORMAT = "MMM DD, YYYY - hh:mm:ss A";
export const TIMESTAMP_FORMAT_V5 = "MMM DD, YYYY hh:mm:ss A";
export const TIMESTAMP_FORMAT_V2 = "MM-DD-YY hh:mm:ss A";
export const SHORT_TIMESTAMP_FORMAT = "MMM DD, YYYY - hh:mm A";
export const SHORT_DATE_FORMAT = "MMM DD, YY";
export const extractUTCDateFromString = (stringDate?: string, format?: string) => {
  if (!stringDate) return "";
  const date = new Date(stringDate + "Z");
  const options = {
    timeZone: "America/New_York",
    timeZoneName: "short",
  };

  // @ts-ignore
  const estTime = date.toLocaleTimeString("en-US", options);
  // @ts-ignore
  const estDate = date.toLocaleDateString("en-US", options);
  const [hour, ampm] = estTime.split(" ");
  const [day] = estDate.split(" ");
  const dateStr = day + " " + hour + " " + ampm;
  return dayjs(dateStr).format(format || TIMESTAMP_FORMAT);
};

export const sortDatesDesc = (date1: string, date2: string) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  if (d1 > d2) return -1;
  if (d1 < d2) return 1;
  return 0;
};
export const extractDateFromString = (stringDate?: string, fullDate: boolean = false) => {
  if (!stringDate) return "N/A";
  const d = new Date(stringDate);
  const formattedDate = d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear();
  return fullDate
    ? `${formattedDate}  ${d.getHours().toString().padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}:${d
        .getSeconds()
        .toString()
        .padStart(2, "0")}`
    : formattedDate;
};
