import React from "react";
import {IContainerProps, Card as SharedCard} from "@bounce/shared/ui";
import useIsMobile from "../../helpers/useIsMobile";

export const Card = ({children, sx, ...props}: IContainerProps) => {
  const isMobile = useIsMobile();
  return (
    <SharedCard
      {...props}
      width={isMobile ? "100%" : "40%"}
      sx={{
        padding: isMobile ? "25px" : "25px 50px",
        ...sx,
      }}
    >
      {children}
    </SharedCard>
  );
};
