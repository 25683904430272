import React from "react";

export const IconCloseDrawer = ({color = "#000"}: {color?: string}) => {
  return (
    <svg width="28" height="27" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12L27.9999 27.9999" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
      <path d="M12.0005 28.0001L28.0004 12.0002" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
    </svg>
  );
};
