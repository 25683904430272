import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "@bounce/shared/requests";
import {UserCommunicationPreferences} from "../../../declerations/server";

type Variables = UserCommunicationPreferences;

type Data = boolean;

export const updateCommunicationPreferences = async (variables: Variables): Promise<Data> => {
  await axiosConsumerAppInstance.put<string, AxiosResponse<string>>(`/user/communication_preferences`, variables);
  return true;
};

export const useUpdateCommunicationPreferences = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(updateCommunicationPreferences, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["user", "communication"],
  });
