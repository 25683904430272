import {alpha} from "@mui/material/styles";
import {GlobalStyles as GlobalThemeStyles} from "@mui/material";
import {Colors} from "@bounce/shared/ui";

export default function GlobalStyles() {
  return (
    <GlobalThemeStyles
      styles={{
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
        html: {
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
        },
        body: {
          width: "100%",
          height: "100%",
        },
        "#root": {
          width: "100%",
          height: "100%",
        },

        img: {display: "block", maxWidth: "100%"},

        // Lazy Load Img
        ".blur-up": {
          WebkitFilter: "blur(5px)",
          filter: "blur(5px)",
          transition: "filter 400ms, -webkit-filter 400ms",
        },
        ".blur-up.lazyloaded ": {
          WebkitFilter: "blur(0)",
          filter: "blur(0)",
        },

        // Perfect Scrollbar
        ".ps__thumb-y, .ps__thumb-x": {
          backgroundColor: alpha(Colors.grey._600, 0.48),
        },
        ".ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking":
          {
            backgroundColor: "transparent",
          },
        ".ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x.ps--clicking .ps__thumb-x, .ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y":
          {
            backgroundColor: alpha(Colors.grey._600, 0.6),
          },
      }}
    />
  );
}
