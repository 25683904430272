import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {UserContextDetails} from "../../../declerations/server";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "@bounce/shared/requests";

type Variables = {userId?: string};

type Data = UserContextDetails;

export const getUserContext = async (variables: Variables): Promise<Data> => {
  if (!variables.userId) {
    // @ts-ignore
    return;
  }
  const result = await axiosConsumerAppInstance.get<Data, AxiosResponse<Data>>(`/user/context/${variables.userId}`);
  return result.data;
};

export const useGetUserContext = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  const queryKey = variables.userId ? ["user", variables.userId, "context"] : ["invalid-user"];
  return useBaseQuery<Data, Variables>(queryKey, getUserContext, variables, options);
};
