import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "@bounce/shared/requests";
import {PaymentPlanOfferDatesResponse} from "../../../declerations/server/payment_plan_models";

type Variables = {
  abstractPaymentPlanName: string;
  startDate: string;
  accountId: string;
};

type Data = PaymentPlanOfferDatesResponse;

export const getPaymentPlanDatesFromPreset = async (variables: Variables): Promise<Data> => {
  const result = await axiosConsumerAppInstance.get<Data, AxiosResponse<Data>>(
    `/payment_plans/dates/${variables.accountId}/${variables.abstractPaymentPlanName}`,
    {params: {start_date: variables.startDate}}
  );
  return result.data;
};

export const useGetPaymentPlanDatesFromPreset = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => useBaseMutation(getPaymentPlanDatesFromPreset, options);
