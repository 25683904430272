import React, {FunctionComponent, ReactNode} from "react";
import {Dialog as MuiDialog} from "@mui/material";
import {Text} from "@bounce/shared/ui";
import {useModal} from "@ebay/nice-modal-react";
import {InputUtils} from "@bounce/shared/helpers";
import useIsMobile from "../../../helpers/useIsMobile";
import {Container, Button, CloseDialogButton} from "@bounce/shared/ui";

export type DialogType = {
  title: string;
  text?: string;
  image?: ReactNode;
  onClick: () => void;
  onClose?: () => void;
  buttonText?: string;
  secondaryButtonText?: string;
  onClickSecondary?: () => void;
  isError?: boolean;
  children?: ReactNode;
};

export const NoticeDialog: FunctionComponent<DialogType> = ({
  title,
  buttonText = "Save",
  text,
  image,
  onClick,
  onClose,
  secondaryButtonText,
  onClickSecondary,
  isError,
}) => {
  const modal = useModal();
  const isMobile = useIsMobile();

  const onClickModal = async () => {
    onClick();
    modal.resolve(true);
    modal.hide();
  };

  const onClickSecondaryModal = async () => {
    onClickSecondary?.();
    modal.hide();
  };

  return (
    <MuiDialog
      open={modal.visible}
      TransitionProps={{
        onExited: () => {
          modal.remove();
          onClose?.();
        },
      }}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",
          padding: isMobile ? "44px 24px 24px 24px" : "44px",
          gap: "24px",
          "&.MuiPaper-elevation": {
            margin: "12px",
          },
          alignItems: "center",
        },
      }}
    >
      <CloseDialogButton onClose={modal.hide} />
      {image}
      <Container sx={{gap: "8px", width: "300px", textAlign: "center"}}>
        <Text variant={"h3"} size={24}>
          {InputUtils.prettierSnakeCaseSentence(title)}
        </Text>
        {text}
      </Container>
      <Container fullWidth sx={{gap: "8px"}}>
        <Button buttonType={isError ? "contained-error" : "contained-primary"} onClick={onClickModal} fullWidth>
          {InputUtils.prettierSnakeCaseSentence(buttonText)}
        </Button>
        {secondaryButtonText && (
          <Button
            buttonType={isError ? "outlined-secondary" : "contained-secondary"}
            onClick={onClickSecondaryModal}
            fullWidth
          >
            {InputUtils.prettierSnakeCaseSentence(secondaryButtonText)}
          </Button>
        )}
      </Container>
    </MuiDialog>
  );
};
