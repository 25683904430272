import React from "react";
import {Box} from "@mui/material";
import {useUi} from "../../contexts";

type IImageFirework = {
  bottom?: number;
  right?: number;
  top?: number;
  left?: number;
};

export const ImgFirework = ({bottom, right, top, left}: IImageFirework) => {
  const {imageFirework} = useUi();
  return imageFirework ? (
    <Box
      component="img"
      src={`data:image/svg+xml,${encodeURIComponent(imageFirework)}`}
      alt="Firework"
      sx={{
        position: "absolute",
        bottom,
        right,
        top,
        left,
        height: "auto",
      }}
    />
  ) : null;
};
