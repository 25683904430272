import React, {FunctionComponent, useCallback, useContext, useState} from "react";
import {FormControl, FormHelperText, Link} from "@mui/material";
import {PaymentType} from "../../../../../declerations/enums";
import {Controller, useForm} from "react-hook-form";
import {EMAIL_REGEX} from "@bounce/shared/constants";
import {PaymentDetails} from "../../../../../declerations/server";
import NiceModal from "@ebay/nice-modal-react";
import TermsAndConditionsDialog from "../TermsAndConditionsDialog";
import {formatInput, PaymentContext} from "../../utils";
import {
  Container,
  Text,
  LoadingButton,
  TextField,
  IconCreditCard,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "../../../../../bounce-ui/consumer";
import {PaymentsImages} from "./PaymentTabs";
import LargePaymentSplitLabel from "../LargePaymentsSplitLabel";
import {Pages} from "../../../../../bi-events/enum";
import OneCentAuthorizationLabel from "../OneCentAuthorizationLabel";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {useUtils} from "../../../../../contexts";

interface CreditCardPaymentFormProps {
  type: PaymentType;
  onCardSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  submitLabelId?: string;
  termsAndConditionsId?: string;
  termsAndConditionsTextId?: string;
  isChangePaymentMethod: boolean;
  primaryEmail?: string;
  userName?: string;
  showEmailField?: boolean;
}

type FormValues = {
  name: string;
  cardNumber: string;
  expiration_date: string;
  cvv: string;
  zip: string;
  email?: string;
  confirmTerms: boolean;
};

const DebitPaymentForm: FunctionComponent<CreditCardPaymentFormProps> = ({
  type,
  onCardSubmit,
  submitLabelId,
  termsAndConditionsId,
  termsAndConditionsTextId,
  isChangePaymentMethod,
  primaryEmail,
  userName,
  showEmailField = true,
}: CreditCardPaymentFormProps) => {
  const {pageClickEvent, enqueueSnackbar, uiSettings} = useUtils();
  const {
    handleSubmit,
    control,
    formState: {isValid},
  } = useForm<FormValues>();
  const {buttonTouched, setButtonTouched, isLoading, showOneCentLabel, showLargeSplitLabel, disableButton} =
    useContext(PaymentContext);
  const [cardInput, setCardInput] = useState<string>("");
  const handleCardChange = (event: any) => {
    const {value} = event.target;
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 16) {
      setCardInput(formatInput(numericValue));
    }
  };

  const onSubmit = handleSubmit(async (data): Promise<void> => {
    const cardNumber = cardInput.replace(/[\s-]/g, "");
    const date = data.expiration_date;
    if (cardNumber.length === 16) {
      setButtonTouched(true);
      const paymentDetails = {
        credit_card: {
          card_number: cardNumber,
          cvv: data.cvv,
          address: "",
          card_holder_name: data.name,
          expiration_date: dayjs(date).format("MMYY"),
          zip_code: data.zip,
        },
        email: data.email || undefined,
      };
      onCardSubmit(type, paymentDetails, isChangePaymentMethod);
    } else {
      enqueueSnackbar(<Text size={14}>Your credit card number is invalid</Text>, {variant: "error"});
    }
  });
  const termsAndConditionsHandler = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    pageClickEvent({page: Pages.PAYMENT_SUMMARY, cta: "t_c"});
    event.preventDefault();
    NiceModal.show(TermsAndConditionsDialog, {termsAndConditionsTextId});
  }, []);

  return (
    <form autoComplete="off" noValidate onSubmit={onSubmit}>
      <Container sx={{gap: "20px"}}>
        {showOneCentLabel && <OneCentAuthorizationLabel type={type} />}
        {showLargeSplitLabel && <LargePaymentSplitLabel />}
        <Controller
          name="cardNumber"
          control={control}
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                value={cardInput}
                onChange={handleCardChange}
                inputRef={ref}
                fullWidth
                label={"Card number"}
                error={invalid}
                placeholder={"xxxx-xxxx-xxxx-xxxx"}
                helperText={error?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconCreditCard />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 80,
                  inputMode: "numeric",
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    alignItems: "center",
                  },
                }}
              />
            );
          }}
        />
        <Controller
          name="name"
          rules={{
            required: "Required field",
          }}
          control={control}
          defaultValue={userName || ""}
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={"Cardholder name"}
                type="text"
                error={invalid}
                helperText={error?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 80,
                }}
              />
            );
          }}
        />
        <Container fullWidth row sx={{gap: "16px"}}>
          <Controller
            name="expiration_date"
            rules={{
              required: "Required field",
            }}
            defaultValue={""}
            control={control}
            render={({field: {ref, onChange, ...field}, fieldState: {invalid, error}}) => {
              return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={onChange}
                    value={field.value ? dayjs(field.value) : null}
                    label={"Expiration date"}
                    minDate={dayjs()}
                    format={"MM/YY"}
                    views={["month", "year"]}
                    disableOpenPicker
                    slotProps={{
                      textField: {
                        InputProps: {
                          sx: {
                            borderRadius: "8px",
                          },
                        },
                        InputLabelProps: {shrink: true},
                      },
                    }}
                    sx={{width: "100%"}}
                  />
                </LocalizationProvider>
              );
            }}
          />
          <Controller
            name="cvv"
            rules={{
              required: "Required field",
            }}
            control={control}
            defaultValue=""
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={"CVV"}
                  placeholder={"XXX"}
                  error={invalid}
                  helperText={error?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 4,
                    inputMode: "numeric",
                  }}
                />
              );
            }}
          />
        </Container>
        <Controller
          name="zip"
          rules={{
            required: "Required field",
          }}
          control={control}
          defaultValue=""
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={"Zip code"}
                placeholder={"XXXXX"}
                error={invalid}
                helperText={error?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 5,
                  inputMode: "numeric",
                }}
              />
            );
          }}
        />
        {showEmailField && (
          <Controller
            name="email"
            rules={{
              pattern: {value: EMAIL_REGEX, message: "Email invalid"},
            }}
            control={control}
            defaultValue={primaryEmail || ""}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={"Email address for receipts"}
                  type="email"
                  error={invalid}
                  helperText={error?.message}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              );
            }}
          />
        )}
        <PaymentsImages />
        <Controller
          name="confirmTerms"
          rules={{
            required: "Required field",
          }}
          control={control}
          defaultValue={false}
          render={({field: {ref, value, ...field}, fieldState: {invalid, error}}) => {
            return (
              <FormControl required error={invalid}>
                <FormControlLabel
                  control={<Checkbox {...field} inputRef={ref} size={"small"} checked={value} />}
                  label={
                    <Text
                      translateId={termsAndConditionsId || "paymentTermsAndConditions"}
                      size={12}
                      values={{
                        termsAndConditions: (
                          <Link href={"#"} onClick={termsAndConditionsHandler}>
                            <Text
                              size={12}
                              translateId={"termsAndConditions"}
                              fontWeight={"bold"}
                              color={"secondary"}
                              sx={{display: "inline"}}
                            />
                          </Link>
                        ),
                        collectionLabel: uiSettings?.collection_label || "",
                      }}
                    />
                  }
                  sx={{alignItems: "start"}}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <LoadingButton
          id={submitLabelId || "Confirm payment"}
          disabled={buttonTouched || disableButton || !isValid}
          loading={isLoading}
          type={"submit"}
        />
      </Container>
    </form>
  );
};
export default DebitPaymentForm;
