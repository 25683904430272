import React, {FunctionComponent, useCallback, useEffect} from "react";
import NiceModal, {NiceModalHocProps} from "@ebay/nice-modal-react";
import {useAmplitude} from "contexts";
import {Pages} from "bi-events/enum";
import {useWebchat} from "components/webchat/components/useWebchat";
import {NoticeDialog} from "bounce-ui/consumer";
import {InputUtils} from "@bounce/shared/helpers";

export type BlockedACHDialogProps = {
  setAchActiveTab: () => void;
  secondaryButtonText: string;
  isAgent: boolean;
  setIsSupervisorView?: (i: boolean) => void;
  closePaymentDialogHandler?: () => void;
};

const BlockedACHDialog: FunctionComponent<BlockedACHDialogProps & NiceModalHocProps> = NiceModal.create(
  ({setAchActiveTab, secondaryButtonText, isAgent, setIsSupervisorView, closePaymentDialogHandler}) => {
    const {pageViewEvent, pageClickEvent} = useAmplitude();
    const {openChat} = useWebchat();

    const gotItHandler = useCallback(async (): Promise<void> => {
      pageClickEvent({page: Pages.BLOCKED_ACH, cta: "got_it"});
    }, []);

    const supervisorApprovalHandler = useCallback(async (): Promise<void> => {
      pageClickEvent({page: Pages.BLOCKED_ACH, cta: secondaryButtonText});
      if (setIsSupervisorView) {
        setIsSupervisorView(true);
      }
      if (setAchActiveTab) {
        setAchActiveTab();
      }
    }, []);

    const ContactUsHandler = useCallback(async (): Promise<void> => {
      pageClickEvent({page: Pages.BLOCKED_ACH, cta: secondaryButtonText});
      if (closePaymentDialogHandler) {
        closePaymentDialogHandler();
      }
      openChat({});
    }, []);

    useEffect(() => {
      pageViewEvent({page: Pages.BLOCKED_ACH_DIALOG});
    }, []);

    return (
      <NoticeDialog
        title={"ACH Unavailable"}
        text={"Due to previous failed ACH payments, only a debit card can be used."}
        buttonText={"Got it"}
        onClick={gotItHandler}
        secondaryButtonText={InputUtils.prettierSnakeCaseSentence(secondaryButtonText)}
        onClickSecondary={isAgent ? supervisorApprovalHandler : ContactUsHandler}
      />
    );
  }
);

export default BlockedACHDialog;
