import React from "react";
import {Colors} from "../colors";

interface IconDocumentProps {
  color?: string;
  size?: number;
}

export const IconDocument: React.FC<IconDocumentProps> = ({color = Colors.grey._800, size = 24}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.33366 1.51294V4.26663C9.33366 4.64 9.33366 4.82669 9.40632 4.96929C9.47024 5.09473 9.57222 5.19672 9.69766 5.26064C9.84027 5.3333 10.027 5.3333 10.4003 5.3333H13.154M9.33366 11.3333H5.33366M10.667 8.66659H5.33366M13.3337 6.65874V11.4666C13.3337 12.5867 13.3337 13.1467 13.1157 13.5746C12.9239 13.9509 12.618 14.2569 12.2416 14.4486C11.8138 14.6666 11.2538 14.6666 10.1337 14.6666H5.86699C4.74689 14.6666 4.18683 14.6666 3.75901 14.4486C3.38269 14.2569 3.07673 13.9509 2.88498 13.5746C2.66699 13.1467 2.66699 12.5867 2.66699 11.4666V4.53325C2.66699 3.41315 2.66699 2.85309 2.88498 2.42527C3.07673 2.04895 3.38269 1.74299 3.75901 1.55124C4.18683 1.33325 4.74689 1.33325 5.86699 1.33325H8.00818C8.49736 1.33325 8.74195 1.33325 8.97212 1.38851C9.17619 1.43751 9.37128 1.51831 9.55023 1.62797C9.75206 1.75166 9.92501 1.92461 10.2709 2.27051L12.3964 4.39599C12.7423 4.7419 12.9153 4.91485 13.0389 5.11668C13.1486 5.29563 13.2294 5.49072 13.2784 5.69479C13.3337 5.92496 13.3337 6.16955 13.3337 6.65874Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
