import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "@bounce/shared/requests";
import {PaymentDetails} from "../../../declerations/server";
import {IResponseData} from "../../../pages/account/payment/utils";

type Variables = PaymentDetails & {
  payment_plan_id?: string;
  account_id: string;
};

type Data = IResponseData;

export const changeNextInstallmentPaymentMethod = async (variables: Variables): Promise<Data> => {
  const {account_id, ...data} = variables;
  let result = await axiosFlexInstance.post<Data, AxiosResponse<Data>>(`/installments/payment-method`, data);
  return result.data;
};

export const useChangeNextInstallmentPaymentMethod = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => {
  return useBaseMutation(changeNextInstallmentPaymentMethod, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["account", variables.account_id],
  });
};
