import {useState} from "react";
import {IActivityFilter} from "../../../../declarations";

export const useActivityFilter = () => {
  const [filters, setFilters] = useState<IActivityFilter>({eventType: [], principal: []});
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const addFilter = (filter: string, category: string) => {
    setFilters(() => {
      if (category === "activityType") {
        return {
          eventType: [...filters.eventType, filter],
          principal: filters.principal,
        };
      } else {
        return {
          eventType: filters.eventType,
          principal: [...filters.principal, filter],
        };
      }
    });
  };

  const removeFilter = (filter: string, category: string) => {
    setFilters(() => {
      let newFilters = {...filters};
      if (category === "activityType") {
        newFilters.eventType = filters.eventType.filter((item) => item !== filter);
      }
      if (category === "principal") {
        newFilters.principal = filters.principal.filter((item) => item !== filter);
      }
      return newFilters;
    });
  };

  const updateFilter = (checked: boolean, filter: string, category: string) => {
    if (checked) {
      addFilter(filter, category);
    } else {
      removeFilter(filter, category);
    }
  };

  return {
    filters,
    setFilters,
    addFilter,
    removeFilter,
    updateFilter,
    applyFilter,
    setApplyFilter,
  };
};
