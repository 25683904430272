import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "@bounce/shared/requests";
import {EventData} from "../../../declerations/server";

type Variables = {
  eventData: EventData;
};

type Data = boolean;

export const addEvent = async (variables: Variables): Promise<Data> => {
  await axiosConsumerAppInstance.post<string, AxiosResponse<string>>(`/events/add_event`, variables.eventData);
  return true;
};

export const useAddEvent = (options?: BaseMutationOptions<Data, Variables>): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(addEvent, options);
