import React, {FunctionComponent, useEffect, useMemo} from "react";
import NiceModal, {NiceModalHocProps} from "@ebay/nice-modal-react";
import {Container, Text, Dialog, IconError} from "../../../../bounce-ui/consumer";
import {useAmplitude, useUi, useUser} from "../../../../contexts";
import {Pages} from "../../../../bi-events/enum";
import {useWebchat} from "../../../../components/webchat/components/useWebchat";
import {InputUtils} from "@bounce/shared/helpers";
import {useGetContactDetailQuery} from "../../../../apis/requests/user/getConatctDetails";

interface IPaymentFailedDialog {
  error: string;
}

const PaymentFailedDialog: FunctionComponent<IPaymentFailedDialog & NiceModalHocProps> = NiceModal.create(({error}) => {
  const {pageViewEvent, pageClickEvent} = useAmplitude();
  const {uiSettings} = useUi();
  const contactDetail = useGetContactDetailQuery({});
  const email = useMemo(() => {
    if (contactDetail?.data) {
      return contactDetail?.data.email;
    }
  }, [contactDetail.data]);
  const {openChat} = useWebchat();
  const {isAgent} = useUser();

  const handleOpenChat = () => {
    pageClickEvent({page: Pages.PAYMENT_FAILED_DIALOG, cta: "got_it"});
    if (!isAgent && email) {
      openChat({
        data: {
          email,
          name: uiSettings?.user_name,
          query: "Hi! I'm having trouble processing a payment. Can you assist me, please?",
        },
      });
    }
  };

  useEffect(() => {
    pageViewEvent({page: Pages.PAYMENT_FAILED_DIALOG});
  }, []);

  return (
    <Dialog
      image={<IconError style={{width: "44px", height: "44px"}} />}
      title={"Payment unsuccessful"}
      buttonText={"Got it"}
      secondaryButtonText={"Chat with us"}
      onClickSecondary={handleOpenChat}
    >
      <Container fullWidth sx={{gap: "8px", alignItems: "start"}}>
        <Text variant={"body3"}>Reason: {InputUtils.prettierSnakeCaseSentence(error)}</Text>
        <Text>
          You can try again with a different payment method or chat with an expert to find the right solution for you.{" "}
        </Text>
      </Container>
    </Dialog>
  );
});

export default PaymentFailedDialog;
