import {SVGProps} from "react";
import {Colors} from "../colors";

interface IconInfoProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const IconInfo = ({color = Colors.secondary._900, ...props}: IconInfoProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_544_2228)">
        <path
          d="M7.74646 14.7303C7.74646 14.4736 7.77212 14.2067 7.82345 13.9296C7.88505 13.6421 7.94151 13.3752 7.99284 13.1288L8.83977 9.21754L9.34793 9.7103L7.50008 9.58711L7.65406 8.81717L10.8262 7.89325L11.473 8.07803L10.3334 13.2828C10.2924 13.4676 10.2565 13.637 10.2257 13.791C10.2051 13.9347 10.1949 14.0579 10.1949 14.1605C10.1949 14.2837 10.2205 14.3813 10.2719 14.4531C10.3334 14.5147 10.4156 14.5455 10.5182 14.5455C10.662 14.5455 10.8057 14.4736 10.9494 14.3299C11.1034 14.1759 11.2984 13.9296 11.5346 13.5908L12.1197 13.9604C11.8939 14.3505 11.6372 14.6995 11.3498 15.0075C11.0726 15.3154 10.7543 15.5618 10.395 15.7466C10.046 15.9211 9.65077 16.0084 9.20934 16.0084C8.71658 16.0084 8.34701 15.8955 8.10063 15.6696C7.86451 15.4335 7.74646 15.1204 7.74646 14.7303ZM10.6568 6.66135C10.3283 6.66135 10.046 6.55869 9.80989 6.35337C9.58404 6.14805 9.47112 5.87601 9.47112 5.53724C9.47112 5.16767 9.60457 4.84943 9.87148 4.58251C10.1487 4.30534 10.4823 4.16675 10.8724 4.16675C11.2214 4.16675 11.5038 4.27454 11.7193 4.49012C11.9349 4.69544 12.0427 4.95722 12.0427 5.27546C12.0427 5.65529 11.9093 5.9838 11.6423 6.26098C11.3857 6.52789 11.0572 6.66135 10.6568 6.66135Z"
          fill={color}
        />
        <path
          d="M10 19.1666C15.0626 19.1666 19.1667 15.0625 19.1667 9.99992C19.1667 4.93731 15.0626 0.833252 10 0.833252C4.93738 0.833252 0.833328 4.93731 0.833328 9.99992C0.833328 15.0625 4.93738 19.1666 10 19.1666Z"
          stroke={color}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_544_2228">
          <rect width="20" height="20" rx="3.33333" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
