import {experimentalStyled as styled} from "@mui/material/styles";
import {DataGrid} from "@mui/x-data-grid";
import {Colors} from "../../colors";

export const DataGridStyled = styled(DataGrid)(({theme}) => ({
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiSvgIcon-root": {
    fill: Colors.grey._600,
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "transparent",
  },
  "& .MuiDataGrid-columnHeadersInner": {
    backgroundColor: "transparent",
  },
  "& .MuiDataGrid-root": {
    "--DataGrid-pinnedBackground": "transparent !important",
    boxSizing: "border-box",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: Colors.tertiary._200,
    "&:hover": {
      backgroundColor: Colors.tertiary._200,
    },
  },
  "& .MuiDataGrid-row": {
    "&:hover": {
      backgroundColor: Colors.tertiary._100,
    },
  },
  "& .MuiDataGrid-cell": {
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: "12px",
    fontWeight: 600,
    color: Colors.grey._1000,
  },
  width: "100%",
}));
