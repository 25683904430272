import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "@bounce/shared/requests";

type Variables = {};

type Data = boolean;

export const getWebchatAvailable = async (variables: Variables): Promise<Data> => {
  const result = await axiosConsumerAppInstance.get<Data, AxiosResponse<Data>>(`/user/webchat_availability`);
  return result.data;
};

export const useGetWebchatAvailableQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  return useBaseQuery<Data, Variables>([], getWebchatAvailable, variables, options);
};
