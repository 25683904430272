import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "@bounce/shared/requests";
import {PaymentDetails} from "../../../declerations/server";
import {PaymentPlanOffer} from "../../../declerations/server/payment_plan_models";
import {IResponseData} from "../../../pages/account/payment/utils";

type Variables = {
  accountId: string;
  userId?: string;
  abstractPaymentPlanName?: string;
  isAgent: boolean;
  startDate?: string;
  paymentDetails?: PaymentDetails;
  paymentPlanOffer?: PaymentPlanOffer;
};
type Data = IResponseData;

export const selectPaymentPlanOfferForAccount = async (variables: Variables): Promise<Data> => {
  let result;
  if (variables.abstractPaymentPlanName) {
    result = await axiosConsumerAppInstance.post<Data, AxiosResponse<Data>>(
      `/payment_plans/select-payment-plan-offer/${variables.abstractPaymentPlanName}`,
      {start_date: variables.startDate, payment_details: variables.paymentDetails, account_id: variables.accountId}
    );
  } else {
    result = await axiosConsumerAppInstance.post<Data, AxiosResponse<Data>>(`/payment_plans/select-custom`, {
      payment_plan_offer: variables.paymentPlanOffer,
      payment_details: variables.paymentDetails,
    });
  }

  return result.data;
};

export const useSelectPaymentPlanOfferForAccount = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => {
  return useBaseMutation(selectPaymentPlanOfferForAccount, {
    ...options,
    prefixKeysToInvalidate: (data, variables) => {
      return [["user"], ["account", variables.accountId]];
    },
  });
};
