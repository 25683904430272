import {axiosConsumerAppInstance, axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "@bounce/shared/requests";
import {FileRead, FileType} from "@bounce/shared/declarations";
import {appendUrlParams} from "@bounce/shared/helpers";

type Variables = {
  userId: string;
  accountId?: string;
  excludeFiles?: FileType[];
  includeFiles?: FileType[];
  fileIds?: string[];
  isAgent?: boolean;
  tab?: string;
};
type Data = FileRead[];

export const getUserFiles = async (variables: Variables): Promise<Data> => {
  let result;
  const appendedQueryParams = appendUrlParams({
    excludeFiles: variables.excludeFiles,
    includeFiles: variables.includeFiles,
    fileIds: variables.fileIds,
  });

  if (variables.isAgent) {
    result = await axiosFlexInstance.get<Data, AxiosResponse<Data>>(`/files/${appendedQueryParams}`, {
      params: {user_id: variables.userId, account_id: variables.accountId},
    });
  } else {
    if (!variables.accountId) {
      // @ts-ignore
      return;
    }
    result = await axiosConsumerAppInstance.get<Data, AxiosResponse<Data>>(
      `/files/${variables.accountId}${appendedQueryParams}`
    );
  }
  return result.data;
};

export const useGetUserFilesQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  return useBaseQuery<Data, Variables>(
    ["user", "files", variables.tab, variables.accountId],
    getUserFiles,
    variables,
    options
  );
};
