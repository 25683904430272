import {axiosConsumerAppInstance, axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "@bounce/shared/requests";

type Variables = {
  account_id: string;
  user_id?: string;
  isAgent?: boolean;
  payment_plan_id: string;
};

type Data = string;

export const deactivatePaymentPlanForAccount = async (variables: Variables): Promise<Data> => {
  let result;
  if (variables.isAgent) {
    result = await axiosFlexInstance.post<Data, AxiosResponse<Data>>(
      `/deactivate-payment-plan/${variables.user_id}/${variables.payment_plan_id}`,
      {revoked_reason: "Agent deactivate payment plan"}
    );
  } else {
    result = await axiosConsumerAppInstance.post<Data, AxiosResponse<Data>>(
      `/payment_plans/deactivate-payment-plan/${variables.payment_plan_id}`,
      {revoked_reason: "User deactivate payment plan"}
    );
  }

  return result.data;
};

export const useDeactivatePaymentPlanForAccount = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => {
  return useBaseMutation(deactivatePaymentPlanForAccount, {
    ...options,
    prefixKeysToInvalidate: (data, variables) => [["user"], ["account", variables.account_id]],
  });
};
