import React from "react";
import {Colors} from "../colors";

type IconProps = {
  color?: string;
  style?: React.CSSProperties;
};

export const IconArrowDown = ({color = Colors.grey._1000, style}: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M12.3333 10.3334L7.99992 6.00004L3.66659 10.3334"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
