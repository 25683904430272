import React from "react";
import {Tabs as MuiTabs, TabsProps, Tab as MuiTab, TabProps} from "@mui/material";
import {styled, Theme} from "@mui/material/styles";
import {Colors} from "@bounce/shared/ui";

// Custom Tab with style overrides
const CustomTab = styled(MuiTab)(({theme}: {theme: Theme}) => ({
  color: theme.palette.text.primary,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  "&.Mui-selected": {
    color: Colors.primary._800,
  },
  "@media (max-width: 600px)": {
    minWidth: 106,
  },
  minHeight: 48,
  flexDirection: "row",
  "& > *:first-of-type": {
    marginBottom: 0,
    marginRight: theme.spacing(1),
  },
  "&.MuiTab-textColorInherit": {
    opacity: 1,
    color: theme.palette.text.secondary,
  },
}));

// Custom Tabs component
const CustomTabs = styled(MuiTabs)(({theme}: {theme: Theme}) => ({
  color: Colors.grey._1000,
}));

export type TabsType = TabsProps & {};

export const Tabs = ({color = "primary", sx, ...props}: TabsType) => {
  return (
    <CustomTabs
      color={color}
      sx={{
        ...sx,
      }}
      {...props}
    />
  );
};

export type TabType = TabProps & {};

export const Tab = ({sx, ...props}: TabType) => {
  return (
    <CustomTab
      sx={{
        ...sx,
      }}
      {...props}
    />
  );
};
