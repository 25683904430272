import React, {FunctionComponent} from "react";
import Box from "@mui/material/Box";
import {Colors, MenuTheme, PermanentMenu, Toolbar} from "@bounce/shared/ui";
import {Outlet} from "react-router-dom";
import {springMenuItems} from "./menu-items";
import {BounceLogo, LogoSpring} from "../../bounce-ui/assets";
import {urls} from "../../routes/urls";

const menuTheme: MenuTheme = {
  backgroundColor: Colors.primary._1000,
  selectedBackgroundColor: Colors.primary._800,
  hoverColor: Colors.primary._900,
};
const SpringLayout: FunctionComponent = () => {
  return (
    <Box sx={{display: "flex", height: "100%"}}>
      <PermanentMenu
        menuItems={springMenuItems}
        logo={<LogoSpring />}
        homeURL={urls.spring.url}
        footer={
          <Toolbar sx={{justifyContent: "center"}}>
            <BounceLogo width={"200px"} height={"40px"} />
          </Toolbar>
        }
        menuTheme={menuTheme}
      />
      <Outlet />
    </Box>
  );
};
export default SpringLayout;
