import React, {FunctionComponent, ReactNode} from "react";
import {Dialog as MuiDialog} from "@mui/material";
import {InputUtils} from "@bounce/shared/helpers";
import {Text, CloseDialogButton, Container} from "@bounce/shared/ui";
import {useUtils} from "../../../contexts";
import {useModal} from "@ebay/nice-modal-react";

export type DialogType = {
  title: string;
  image?: ReactNode;
  onClose?: () => void;
  alignCenter?: boolean;
  background?: string;
  children?: ReactNode;
};

export const CustomDialog: FunctionComponent<DialogType> = ({
  title,
  image,
  onClose,
  alignCenter = false,
  background = undefined,
  children,
}) => {
  const {isMobile} = useUtils();
  const modal = useModal();
  return (
    <MuiDialog
      open={modal.visible}
      TransitionProps={{
        onExited: () => {
          onClose?.();
          modal.remove();
        },
      }}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",
          padding: isMobile ? "44px 24px 24px 24px" : "44px",
          gap: "24px",
          "&.MuiPaper-elevation": {
            margin: "12px",
          },
          ...(background && {background}),
        },
      }}
    >
      <CloseDialogButton onClose={modal.hide} color={!!background} />
      <Container sx={{gap: "24px", width: "300px", alignItems: alignCenter ? "center" : "start"}}>
        {image}
        <Text variant={"h3"} size={24}>
          {InputUtils.prettierSnakeCaseSentence(title)}
        </Text>
        {children}
      </Container>
    </MuiDialog>
  );
};
