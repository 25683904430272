import React, {useCallback, useContext, useMemo, useState} from "react";
import {FormControl, InputLabel, Link, MenuItem, Select} from "@mui/material";
import NiceModal from "@ebay/nice-modal-react";
import PaymentTabs from "../../../../pages/account/payment/components/payment-form/PaymentTabs";
import {PaymentType} from "../../../../declerations/enums";
import {PaymentDetails} from "../../../../declerations/server";
import {openPaymentFailedDialog, PaymentContext} from "../../../../pages/account/payment/utils";
import {Container, LoadingButton, Text, Checkbox} from "../../../../bounce-ui/consumer";
import {
  getDayFromDate,
  IHighlightedInstallment,
  mapMethodToValue,
  PaymentMethodType,
} from "../../../../pages/account/account-dashboard/component-v2/utils";
import TermsAndConditionsDialog from "../../../../pages/account/payment/components/TermsAndConditionsDialog";
import {Pages} from "../../../../bi-events/enum";
import {useUser, useUtils} from "../../../../contexts";
import {useGetUserQuery} from "../../../../apis/requests/user/getUser";
import {useUpdateInstallmentDate} from "../../../../apis/requests/user/updateInstallmentDate";
import dayjs from "dayjs";
import {useChangeNextInstallmentPaymentMethod} from "../../../../apis/requests/payments";
import {PAYMENT_TERMS_AND_CONDITIONS} from "../../../../constants/disclaimers";
import {PaymentMethodTypes} from "@bounce/shared/enums";

export type IPaymentMethod = {
  accountId: string;
  lastDigitsCard?: string | undefined;
  title?: string;
  submitTitle?: string;
  currentMethodType?: PaymentMethodTypes;
  paymentHandler: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  disable?: boolean;
  page?: Pages;
  termsAndConditionsId?: string;
  closeHandler?: () => void;
  paymentPlanId?: string;
  isMethodNextInstallmentOnly?: boolean;
  highlightedInstallment?: IHighlightedInstallment;
  paymentDate?: dayjs.Dayjs;
};

const PaymentMethod = ({
  accountId,
  lastDigitsCard,
  title,
  paymentHandler,
  submitTitle = "Pay it now",
  disable = false,
  currentMethodType,
  page,
  closeHandler,
  termsAndConditionsId = "paymentTermsAndConditions",
  paymentPlanId,
  isMethodNextInstallmentOnly = false,
  highlightedInstallment = undefined,
  paymentDate = undefined,
}: IPaymentMethod) => {
  const {uiSettings, pageClickEvent, enqueueSnackbar} = useUtils();
  const [checkedTerms, setCheckedTerms] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>("current");
  const {isAgent} = useUser();
  const updateInstallment = useUpdateInstallmentDate({});
  const {buttonTouched, isLoading, setButtonTouched} = useContext(PaymentContext);
  const changeNextInstallmentPaymentMethod = useChangeNextInstallmentPaymentMethod({refetchType: "active"});
  const {data: user} = useGetUserQuery({});
  const achBlocked = useMemo(() => {
    if (isAgent) {
      return false;
    }
    return user && !user.ach_allowed && currentMethodType === "ach";
  }, [user]);

  const termsAndConditionsHandler = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    if (page) {
      pageClickEvent({page, cta: "t_c"});
    }
    event.preventDefault();
    NiceModal.show(TermsAndConditionsDialog, {
      termsAndConditionsTextId: PAYMENT_TERMS_AND_CONDITIONS,
    });
  }, []);

  const paymentMethodOption = useMemo(() => {
    const options = mapMethodToValue({
      currentMethodType,
      lastDigitsCard,
      showRemoveOption: isMethodNextInstallmentOnly,
    });
    if (!Object.keys(options).includes("current") || achBlocked) {
      setPaymentMethod("change");
    }
    return options;
  }, [user]);

  const hasPaymentMethod = paymentMethodOption && lastDigitsCard;

  const updatePaymentDueDate = async () => {
    if (highlightedInstallment && paymentDate) {
      const {data, status} = await updateInstallment.mutateAsync({
        installmentId: highlightedInstallment.id,
        dueDate: getDayFromDate(paymentDate),
        accountId,
      });
      if (data) {
        enqueueSnackbar(<Text size={14}>Update installment date successfully</Text>, {variant: "success"});
        return status;
      }
    }
  };

  const changeNextInstallment = async (paymentDetails: PaymentDetails) => {
    if (paymentPlanId && (!hasPaymentMethod || paymentMethod !== "current")) {
      const result = await changeNextInstallmentPaymentMethod.mutateAsync({
        ...paymentDetails,
        account_id: accountId,
        payment_plan_id: paymentPlanId,
      });
      if (result.success) {
        enqueueSnackbar(<Text size={14}>Changes to your payment method were saved</Text>, {variant: "success"});
        closeHandler?.();
      } else {
        openPaymentFailedDialog(result.error_message || "Saving changes failed");
        setButtonTouched(false);
      }
    } else {
      closeHandler?.();
    }
  };

  const changeNextInstallmentPaymentMethodHandler = useCallback(
    async (paymentType: PaymentType, paymentDetails: PaymentDetails): Promise<void> => {
      try {
        let updateInstallmentResult = null;
        const shouldUpdateDate = !dayjs(highlightedInstallment?.due_date).isSame(paymentDate, "day");
        if (shouldUpdateDate) {
          updateInstallmentResult = await updatePaymentDueDate();
        }
        if (!shouldUpdateDate || updateInstallmentResult === 200) {
          await changeNextInstallment(paymentDetails);
        } else {
          closeHandler?.();
        }
      } catch (e) {
        enqueueSnackbar(<Text size={14}>Failed to change your payment method</Text>, {variant: "error"});
      }
    },
    [accountId, paymentDate, paymentMethod]
  );

  const getPaymentHandler = (paymentMethod: string, isMethodNextInstallmentOnly: boolean) => {
    if (
      paymentMethod === "remove" ||
      (isMethodNextInstallmentOnly && !(submitTitle === "Pay it now" || submitTitle === "Pay now"))
    ) {
      return changeNextInstallmentPaymentMethodHandler;
    }
    return paymentHandler;
  };

  const chosenPaymentHandler = getPaymentHandler(paymentMethod, isMethodNextInstallmentOnly);

  return (
    <Container fullWidth sx={{alignItems: "start", gap: "20px"}}>
      <Container fullWidth sx={{alignItems: "start", gap: "15px"}}>
        {!achBlocked && (
          <>
            {hasPaymentMethod && (
              <FormControl variant="outlined" sx={{width: "100%"}}>
                <InputLabel>{title || "Payment method"}</InputLabel>
                <Select
                  value={paymentMethod}
                  label={title || "Payment method"}
                  onChange={(event: any) => setPaymentMethod(event.target.value)}
                  sx={{
                    height: "50px",
                    borderRadius: "8px",
                  }}
                >
                  {Object.keys(paymentMethodOption).map((method) => (
                    <MenuItem key={method} value={method}>
                      {paymentMethodOption[method]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        )}
        {(!lastDigitsCard || paymentMethod === "change") && (
          <PaymentTabs
            onCardSubmit={chosenPaymentHandler}
            onAchSubmit={chosenPaymentHandler}
            submitLabelId={submitTitle}
            termsAndConditionsId={termsAndConditionsId}
            termsAndConditionsTextId={PAYMENT_TERMS_AND_CONDITIONS}
            isDashboardDialog={true}
            closePaymentDialogHandler={closeHandler}
          />
        )}
      </Container>
      {lastDigitsCard && ["current", "remove"].includes(paymentMethod) && (
        <Container fullWidth>
          {paymentMethod === "current" && (
            <Container row sx={{alignItems: "start", gap: "0px"}}>
              <Checkbox size={"small"} checked={checkedTerms} onClick={() => setCheckedTerms(!checkedTerms)} />
              <Text
                translateId={termsAndConditionsId}
                size={12}
                values={{
                  termsAndConditions: (
                    <Link href={"#"} onClick={termsAndConditionsHandler}>
                      <Text
                        size={12}
                        translateId={"termsAndConditions"}
                        fontWeight={"bold"}
                        color={"secondary"}
                        sx={{display: "inline"}}
                      />
                    </Link>
                  ),
                  collectionLabel: uiSettings?.collection_label || "",
                }}
              />
            </Container>
          )}
          <LoadingButton
            id={paymentMethod === "remove" ? "Save and update" : submitTitle}
            disabled={paymentMethod !== "remove" && (!checkedTerms || disable || buttonTouched)}
            onClick={chosenPaymentHandler}
            loading={isLoading}
          />
        </Container>
      )}
    </Container>
  );
};

export default PaymentMethod;
