import {IconButton} from "@mui/material";
import React from "react";
import {Colors} from "../colors";
import {IconCloseDrawer} from "../assets";

interface ICloseDialogButton {
  onClose: () => void;
  color?: boolean;
}

export const CloseDialogButton = ({onClose, color}: ICloseDialogButton) => {
  return (
    <IconButton
      aria-label="close"
      size="small"
      onClick={onClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        zIndex: 10,
      }}
    >
      <IconCloseDrawer color={color ? Colors.bw.white : Colors.bw.black} />
    </IconButton>
  );
};
