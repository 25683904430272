import JSZip from "jszip";
import {DownloadLink, FileType} from "../declarations";
import {GridRowSelectionModel} from "@mui/x-data-grid";
import _, {isEmpty} from "lodash";
import {FileRejection} from "react-dropzone";
import {gzip} from "pako";

const downloadFilesAsZip = async ({files, accountPublicId}: {files: DownloadLink[]; accountPublicId?: string}) => {
  const zip = new JSZip();
  try {
    for (const file of files) {
      const response = await fetch(file.url);
      if (response.ok) {
        const fileBlob = await response.blob();
        zip.file(file.file_name, fileBlob);
      }
    }
    const zipBlob = await zip.generateAsync({type: "blob"});
    const link = document.createElement("a");
    link.href = URL.createObjectURL(zipBlob);
    if (accountPublicId) {
      link.download = `Docs-${accountPublicId}.zip`;
    } else {
      link.download = `Docs.zip`;
    }
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (error) {}
};
const getDownloadLinkFromRow = (selected: GridRowSelectionModel, mapIdToUrl: Record<string, DownloadLink>) => {
  return selected.map((fileId) => {
    const downloadLink = mapIdToUrl[String(fileId)]; // Use String for key lookup
    return downloadLink || {url: "", file_name: ""}; // Provide a fallback if not found
  });
};

const appendUrlParams = ({
  excludeFiles,
  includeFiles,
  fileIds,
}: {
  excludeFiles?: FileType[];
  includeFiles?: FileType[];
  fileIds?: string[];
}) => {
  const hasIncludedFiles = includeFiles?.length;
  const hasExcludedFiles = excludeFiles?.length;
  let appendedQueryParams = "";
  if (hasIncludedFiles) {
    appendedQueryParams += getIncludeFileQueryParam(includeFiles);
  }
  if (hasExcludedFiles) {
    appendedQueryParams += getExcludeFileQueryParam(excludeFiles);
  }
  if (fileIds) {
    appendedQueryParams += getFileIdsQueryParam(fileIds);
  }
  return isEmpty(appendedQueryParams) ? "" : `?${appendedQueryParams}`;
};

const getExcludeFileQueryParam = (excludeFiles: FileType[]): string => {
  return excludeFiles.map((fileType) => `exclude_files=${fileType}`).join("&");
};

const getIncludeFileQueryParam = (includeFiles: FileType[]): string => {
  return includeFiles.map((fileType) => `include_files=${fileType}`).join("&");
};

const getFileIdsQueryParam = (fileIds: string[]): string => {
  return fileIds.map((fileId) => `file_ids=${fileId}`).join("&");
};

const getFilesRejectedMessageKey = (fileRejections?: FileRejection | FileRejection[]): string => {
  if (!fileRejections) {
    return "invalidFieldUpload";
  }

  if (_.isArray(fileRejections) && _.isEmpty(fileRejections)) {
    return "invalidFieldUpload";
  }

  const fileRejection: FileRejection = _.isArray(fileRejections) ? fileRejections[0] : fileRejections;
  const {errors} = fileRejection;

  if (!!errors?.length) {
    const error = errors[0];
    const {code} = error;

    switch (code) {
      case "file-too-large":
        return "invalidFieldUploadTooLarge";
      case "file-too-small":
        return "invalidFieldUploadTooSmall";
      case "too-many-files":
        return "invalidFieldUploadTooManyFiles";
      case "file-invalid-type":
        return "invalidFieldUploadInvalidType";
      default:
        return "invalidFieldUpload";
    }
  }

  return "invalidFieldUpload";
};

const isNotChromeOniPhone = () => {
  const userAgent = navigator.userAgent;
  const isiPhone = /iPhone/i.test(userAgent);
  const isChrome = /CriOS/i.test(userAgent);

  return isiPhone && !isChrome;
};

const getViewMode = (fileName?: string, viewMode?: boolean): boolean => {
  if (!viewMode || !fileName) {
    return false;
  }
  const isSafari = isNotChromeOniPhone();

  return (
    !isSafari &&
    ["pdf", "html", "png", "jpeg", "jpg", "svg", "svg+xml", "tif", "tiff", "mp3"].some((fileType) =>
      fileName?.endsWith(fileType)
    )
  );
};

const compressFile = async (file: File): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      try {
        const compressed = gzip(new Uint8Array(reader.result as ArrayBuffer));
        const blobFile = new Blob([compressed], {type: "application/gzip"});
        resolve(blobFile);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => reject(reader.error);
  });
};

export {
  downloadFilesAsZip,
  getDownloadLinkFromRow,
  appendUrlParams,
  getFilesRejectedMessageKey,
  getViewMode,
  compressFile,
};
