import React, {FC, useEffect, useState} from "react";
import {map, some} from "lodash";
import {useNavigate} from "react-router-dom";
import {ICategoryListItem, ICategoryNestListItem, ISublistItem, MenuTheme} from "./types";
import {Colors} from "../../colors";
import {Container} from "../Container";
import {Collapse, List, ListItem, ListItemButton, ListItemIcon, Text} from "../../components";
import {IconArrowUp} from "../../assets";
import {InputUtils} from "../../../helpers";
import {useLocation} from "react-use";

const listItemButtonStyle = (selected: boolean, hoverColor?: string, selectedBackgroundColor?: string) => {
  return {
    borderRadius: "6px",
    gap: "12px",
    paddingLeft: "8px",
    paddingRight: "8px",
    backgroundColor: selected ? selectedBackgroundColor || Colors.primary._800 : null,
    "&:hover": {
      backgroundColor: hoverColor || Colors.primary._900,
      color: Colors.bw.white,
    },
  };
};

export type CategoryListItemProps = {
  selected?: string;
  setSelected: (x: string) => void;
  item: ICategoryListItem;
  menuTheme: MenuTheme;
};

export const CategoryListItem: FC<CategoryListItemProps> = ({item, selected, setSelected, menuTheme}) => {
  const navigate = useNavigate();
  const isSelected = selected === item.id;

  const onClickItem = () => {
    if (item?.onClick) {
      item.onClick();
    }
    if (item?.url) {
      navigate(item.url);
      setSelected(item.id);
    }
  };

  return (
    <ListItem key={item.title} disablePadding>
      <ListItemButton
        sx={listItemButtonStyle(isSelected, menuTheme.hoverColor, menuTheme.selectedBackgroundColor)}
        onClick={onClickItem}
      >
        <ListItemIcon sx={{minWidth: 0}}>{item.icon}</ListItemIcon>
        <Text variant="body3" sx={{color: Colors.bw.white}}>
          {InputUtils.capitalizeString(item.title)}
        </Text>
      </ListItemButton>
    </ListItem>
  );
};

export type CategoryNestListItemProps = {
  item: ICategoryNestListItem;
  selected?: string;
  setSelected: (x: string) => void;
  menuTheme: MenuTheme;
};
export const CategoryNestListItem: FC<CategoryNestListItemProps> = ({item, selected, setSelected, menuTheme}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const {pathname} = useLocation();

  useEffect(() => {
    if (some(item.subListItems, ["url", pathname])) {
      setOpen(true);
    }
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          ...listItemButtonStyle(false, menuTheme.hoverColor, menuTheme.selectedBackgroundColor),
          justifyContent: "space-between",
        }}
      >
        <Container row>
          <ListItemIcon sx={{minWidth: 0}}>{item.icon}</ListItemIcon>
          <Text variant="body3" sx={{color: Colors.bw.white}}>
            {InputUtils.capitalizeString(item.title)}
          </Text>
        </Container>
        {open ? <IconArrowUp /> : <IconArrowUp style={{transform: "rotate(180deg)"}} />}
      </ListItemButton>
      <Collapse in={open} unmountOnExit>
        <List component="div" disablePadding>
          {map(item.subListItems, (subItem: ISublistItem) => {
            const isSelected = selected === subItem.id;
            return (
              <ListItemButton
                key={subItem.title}
                onClick={() => {
                  navigate(subItem.url);
                  setSelected(subItem.id);
                }}
                sx={{
                  ...listItemButtonStyle(isSelected, menuTheme.hoverColor, menuTheme.selectedBackgroundColor),
                  paddingLeft: "50px",
                }}
              >
                <Text variant="body1" sx={{color: Colors.bw.white}}>
                  {InputUtils.capitalizeString(subItem.title)}
                </Text>
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
