import React, {ReactNode} from "react";
import {Link as MuiLink} from "@mui/material";
import {ITextProps} from "@bounce/shared/ui";
import {useIntl} from "react-intl";

export interface ILink extends ITextProps {
  text?: string;
  onClick: (event: any) => void;
  href?: string;
  children?: ReactNode;
}

export interface ILinkHref extends ITextProps {
  text?: string;
  href?: string;
}

export const Link = ({translateId, text, onClick, href, size = 16, variant = "body3", children, sx}: ILink) => {
  const intl = useIntl();
  return (
    <MuiLink
      onClick={onClick}
      href={href}
      /*
        // @ts-ignore */
      variant={variant}
      className={"Link"}
      sx={{
        fontSize: `${size}px`,
        display: "inline",
        cursor: "pointer",
        textDecoration: "none",
        ...(!children && {
          "&:hover": {
            textDecoration: "underline",
          },
        }),
        ...sx,
      }}
    >
      {translateId ? intl.formatMessage({id: translateId}) : text}
      {children}
    </MuiLink>
  );
};

export const LinkHref = ({translateId, text, href, size = 16, variant = "body1", sx}: ILinkHref) => {
  const intl = useIntl();

  return (
    <MuiLink
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      /*
        // @ts-ignore */
      variant={variant}
      className={"LinkHref"}
      sx={{
        textDecoration: "underline",
        display: "inline",
        cursor: "pointer",
        fontSize: `${size}px`,
        color: (theme) => theme.palette.text.primary,
        ...sx,
      }}
    >
      {translateId ? intl.formatMessage({id: translateId}) : text}
    </MuiLink>
  );
};
