import React, {FunctionComponent, ReactElement, useCallback, useMemo} from "react";
import {Box} from "@mui/material";
import {DropEvent, DropzoneProps, FileRejection} from "react-dropzone";
import Dropzone from "react-dropzone";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {isEmpty} from "lodash";
import {Container, Text} from "../index";
import {FilesPreview} from "./FilePreview";
import {useSnackbar} from "notistack";
import {Colors} from "../../colors";
import {IconUpload} from "../../assets";
import {getFilesRejectedMessageKey} from "../../../helpers";

export enum MaxFileSizeOption {
  NON_GZ_SIZE_LIMIT = 10,
  GZ_SIZE_LIMIT = 50,
}

interface FileDropzoneProps extends DropzoneProps {
  sx?: SxProps<Theme>;
  addFilesText?: string | ReactElement;
  files: File[];
  onChange: (event: any) => void;
  maxFiles?: number;
  textPosition?: "start" | "center";
  maxSize?: MaxFileSizeOption;
  height?: string;
  color?: string;
  isDialog?: boolean;
  isMobile?: boolean;
  isAgent?: boolean;
  rowLength?: string;
}

const notExistsOnArray = ({newFile, files}: {newFile: File; files: File[]}) => {
  return !files.some((file) => file.name === newFile.name);
};

const mapMaxSizeOptionToBytes = {
  [MaxFileSizeOption.NON_GZ_SIZE_LIMIT]: 10485760,
  [MaxFileSizeOption.GZ_SIZE_LIMIT]: 52428800,
};

export const FileDropzone: FunctionComponent<FileDropzoneProps> = ({
  sx,
  addFilesText,
  files,
  onChange,
  maxFiles,
  textPosition = "start",
  maxSize,
  height,
  color,
  isDialog = true,
  isMobile = false,
  isAgent = false,
  rowLength,
  ...dropzoneProps
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const dropFilesText = addFilesText ?? (isMobile ? "Upload files" : "Drop files here or click to upload");
  const onDropInternal = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      if (!isEmpty(acceptedFiles)) {
        const appendedFiles: File[] = [];
        acceptedFiles.forEach((newFile) => {
          if (notExistsOnArray({newFile, files})) {
            appendedFiles.push(newFile);
          }
        });
        onChange([...files, ...appendedFiles]);
      }

      if (!isEmpty(fileRejections)) {
        fileRejections.forEach((fileRejection) =>
          enqueueSnackbar(<Text translateId={getFilesRejectedMessageKey(fileRejection)} size={14} />, {
            variant: "error",
          })
        );
      }
    },
    [files]
  );

  const isAboveMaxFiles = !!(maxFiles && files.length > maxFiles);

  const hasFiles = useMemo<boolean>(() => !isEmpty(files), [files]);
  const maxSizeInBytes = (maxSize && mapMaxSizeOptionToBytes[maxSize]) ?? undefined;
  return (
    <Dropzone {...dropzoneProps} onDrop={onDropInternal} maxFiles={maxFiles} maxSize={maxSizeInBytes}>
      {({getRootProps, getInputProps}) => (
        <Container fullWidth sx={{alignItems: "center"}}>
          {hasFiles && (
            <FilesPreview
              files={files}
              onChange={onChange}
              isDialog={isDialog}
              isAgent={isAgent}
              isMobile={isMobile}
              rowLength={rowLength}
            />
          )}
          <Box
            {...getRootProps({})}
            sx={{
              cursor: "pointer",
              width: "100%",
              height: height ?? "90px",
              color: color ?? Colors.grey._800,
              backgroundColor: Colors.grey._100,
              borderRadius: "8px",
              border: `1px dashed ${Colors.grey._1000}`,
              ...sx,
            }}
          >
            <input {...getInputProps()} />
            <Container fullWidth sx={{height: "100%", justifyContent: "center", gap: height ? "5px" : "10px"}}>
              <Text variant={"body3"} sx={{color: color ?? Colors.grey._800}}>
                {dropFilesText}
              </Text>
              <IconUpload color={color ?? Colors.grey._800} />
            </Container>
            {isAboveMaxFiles && <Text>Can import one file only</Text>}
          </Box>
          {dropzoneProps.disabled && (
            <Box
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: (theme) => theme.palette.action.disabledBackground,
                opacity: (theme) => theme.palette.action.disabledOpacity,
              }}
            />
          )}
        </Container>
      )}
    </Dropzone>
  );
};
