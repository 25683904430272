import {Theme} from "@mui/material/styles";
import {Components} from "@mui/material/styles/components";
import {Colors} from "../../colors";

// ----------------------------------------------------------------------

export default function Tooltip(theme: Theme): Components {
  return {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          boxShadow: `0px 0px 10px ${Colors.grey._300}`,
          marginRight: "10px",
        },
        arrow: {
          color: theme.palette.common.white,
        },
      },
    },
  };
}
