import {axiosConsumerAppInstance, axiosFlexInstance} from "../../ServerApiManager";
import axios from "axios";
import {DownloadLink} from "@bounce/shared/declarations";
import {getViewMode} from "@bounce/shared/helpers";

type Variables = {
  accountId?: string;
  fileId: string;
  isAgent?: boolean;
  isMobile?: boolean;
  viewMode?: boolean;
  fileName?: string;
  htmlView?: boolean;
};

export const getUserFile = async (variables: Variables): Promise<DownloadLink> => {
  let result;
  if (variables?.isAgent) {
    result = await axiosFlexInstance.get<DownloadLink>(`/files/download-link/${variables.fileId}`, {
      params: {view_mode: variables.viewMode},
    });
  } else {
    result = await axiosConsumerAppInstance.get<DownloadLink>(
      `/files/download-link/${variables.accountId}/${variables.fileId}`,
      {params: {view_mode: variables.viewMode}}
    );
  }
  return result.data;
};

export const downloadUserFiles = async (variables: Variables): Promise<void | string> => {
  const viewMode = getViewMode(variables.fileName, variables.viewMode);
  variables.viewMode = viewMode;
  const {url, file_name} = await getUserFile(variables);
  if (variables.htmlView) {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "text/html",
      },
    });
    return response.data;
  }
  const link = document.createElement("a");
  link.href = url;
  link.download = file_name;
  if (viewMode) {
    link.target = "_blank";
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
