import {LoadingButton as MuiLoadingButton} from "@mui/lab";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material";
import {Colors} from "@bounce/shared/ui";

export interface ILoadingButtonProps {
  id: string;
  size?: "large" | "small" | "medium" | undefined;
  variant?: "contained" | "text" | "outlined";
  color?: "secondary" | "inherit" | "primary" | "success" | "error" | "info" | "warning" | undefined;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  fullWidth?: boolean;
  onClick?: any;
  type?: "button" | "reset" | "submit" | undefined;
  autoFocus?: boolean;
  sx?: SxProps<Theme>;
}

export const LoadingButton = ({
  id,
  size = "large",
  variant = "contained",
  color = "primary",
  fullWidth = true,
  disabled,
  loading,
  className = "LoadingButton",
  onClick,
  autoFocus = false,
  type,
  sx,
}: ILoadingButtonProps) => {
  return (
    <MuiLoadingButton
      className={className}
      size={size}
      variant={variant}
      color={color}
      fullWidth={fullWidth}
      disabled={disabled}
      loading={loading}
      onClick={onClick || null}
      type={type}
      autoFocus={autoFocus}
      sx={{
        fontWeight: 600,
        borderRadius: 999,
        ...(loading && {
          "&.Mui-disabled": {
            background: Colors.primary._800,
          },
        }),
        ...sx,
      }}
    >
      {id}
    </MuiLoadingButton>
  );
};
