import React, {ReactNode} from "react";
import {Box, Theme} from "@mui/material";
import {SxProps} from "@mui/system";
export interface IContainerProps {
  children: ReactNode;
  fullWidth?: boolean;
  row?: boolean;
  width?: number | string;
  className?: string;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}
export const Container = ({
  children,
  fullWidth,
  row = false,
  width,
  className = "Container",
  onClick,
  sx,
}: IContainerProps) => {
  return (
    <Box
      className={className}
      onClick={onClick}
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: row ? "row" : "column",
        width: fullWidth ? "100%" : width,
        gap: "10px",
        ...(onClick && {cursor: "pointer"}),
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
