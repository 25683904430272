import React from "react";
import dayjs from "dayjs";
import {ChatMessageData} from "../../../../declarations";
import {CommunicateBy} from "../../../../enums";
import {IconChatOut, IconChatIn} from "../../../assets";
import {Container} from "../../Container";
import {Text} from "../../Text";
import {TIMESTAMP_FORMAT} from "../../../../helpers";
import {getNameFromEmail} from "../utils";

interface IChatMessage {
  chatMessage: ChatMessageData;
  principal?: string;
}

const getAuthorName = (isAgent: boolean, authorName: string, principal?: string) => {
  if (!isAgent && !authorName) return CommunicateBy.SYSTEM;
  if (isAgent) {
    return principal ? getNameFromEmail(principal) : CommunicateBy.AGENT;
  } else {
    return authorName ?? CommunicateBy.USER;
  }
};

export const ChatMessage = ({chatMessage, principal}: IChatMessage) => {
  const {body, isAgent, timestamp, authorName} = chatMessage;
  const author = getAuthorName(isAgent, authorName, principal);
  const icon = isAgent || author === CommunicateBy.SYSTEM ? <IconChatOut /> : <IconChatIn />;
  return (
    <Container fullWidth row sx={{gap: "16px", justifyContent: "space-between", alignItems: "start"}}>
      <Container row sx={{alignItems: "start"}}>
        {icon}
        <Container sx={{alignItems: "start"}}>
          <Text size={11} fontWeight={"medium"} sx={{textTransform: "capitalize"}}>
            {author}
          </Text>
          <Text size={14} fontWeight={"bold"}>
            {`"${body}"`}
          </Text>
        </Container>
      </Container>
      <Text size={11} fontWeight={"medium"} sx={{whiteSpace: "nowrap"}}>
        {dayjs(timestamp).format(TIMESTAMP_FORMAT)}
      </Text>
    </Container>
  );
};
