import React, {FunctionComponent, useEffect, useState} from "react";
import {LocalizationProvider, StaticDatePicker, TimeClockProps} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import NiceModal, {NiceModalHocProps} from "@ebay/nice-modal-react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {styled} from "@mui/material/styles";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {Dialog} from "./index";
import {Container, Colors} from "@bounce/shared/ui";
import {useAmplitude} from "../../contexts";
import {Pages} from "../../bi-events/enum";
dayjs.extend(utc);
dayjs.extend(timezone);

const getFutureDate = (date: Dayjs, daysToAdd: number): Dayjs => {
  return date.add(daysToAdd, "day");
};

interface IChooseDateProps {
  title?: string;
  subtitle?: string;
  onConfirm: (event: any) => void;
  shouldDisableDate?: (date: unknown) => boolean;
  startDate: Dayjs;
  isPastAllowed?: boolean;
  isChooseYearAllowed?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}
const StyledArrowLeft = styled(KeyboardArrowLeft)<{disabled: boolean}>(({theme}) => ({
  color: Colors.grey._900,
}));

const StyledArrowRight = styled(KeyboardArrowRight)<{disabled: boolean}>(({theme}) => ({
  color: Colors.grey._900,
}));

export const slots: TimeClockProps<any>["slots"] = {
  leftArrowIcon: StyledArrowLeft,
  rightArrowIcon: StyledArrowRight,
};

const ChooseDateDialog: FunctionComponent<IChooseDateProps & NiceModalHocProps> = NiceModal.create(
  ({
    title,
    subtitle = "When do you want to make this payment?",
    onConfirm,
    shouldDisableDate,
    startDate,
    isPastAllowed = false,
    isChooseYearAllowed = false,
    minDate,
    maxDate,
  }) => {
    const {pageViewEvent, pageClickEvent} = useAmplitude();
    const [selectedDate, setSelectedDate] = useState<Dayjs>(startDate ?? dayjs());
    const confirmHandler = (): void => {
      pageClickEvent({page: Pages.CHOOSE_DATE, cta: "set_date", data: selectedDate});
      onConfirm(selectedDate);
    };

    const getMinDate = (): Dayjs | null => {
      if (minDate) {
        return minDate;
      }
      return isPastAllowed ? null : startDate.tz("America/New_York");
    };

    const getMaxDate = (): Dayjs | null => {
      if (maxDate) {
        return maxDate;
      }
      return dayjs(getFutureDate(getMinDate() || dayjs(), 30));
    };

    useEffect(() => {
      pageViewEvent({page: Pages.CHOOSE_DATE});
    }, []);

    return (
      <Dialog title={title || "Payment date"} onClick={confirmHandler} buttonText={"Set"}>
        {subtitle}
        <Container
          fullWidth
          sx={{
            gap: "5px",
            justifyContent: "space-around",
            overflow: "auto",
            border: `1px solid ${Colors.grey._500}`,
            borderRadius: "8px",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              defaultValue={dayjs()}
              value={selectedDate || null}
              minDate={getMinDate()}
              maxDate={getMaxDate()}
              onChange={(event: any) => {
                setSelectedDate(event);
              }}
              shouldDisableDate={shouldDisableDate}
              views={isChooseYearAllowed ? ["day", "year"] : ["day"]}
              slots={slots}
              slotProps={{
                actionBar: {
                  actions: [],
                },
                toolbar: {hidden: true},
              }}
            />
          </LocalizationProvider>
        </Container>
      </Dialog>
    );
  }
);

export {ChooseDateDialog, getFutureDate};
