import {FunctionComponent} from "react";
import {experimentalStyled as styled} from "@mui/material/styles";

interface EmptySearchResultsProps {}

const MessageWrapper = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "12px",
  paddingTop: "40px",
}));

const EmptySearchResults: FunctionComponent<EmptySearchResultsProps> = () => {
  return (
    <MessageWrapper>
      <h1>No Matching Users</h1>
      <div>Please change your search term to get more results</div>
    </MessageWrapper>
  );
};

export default EmptySearchResults;
