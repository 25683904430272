import React, {FunctionComponent, useMemo, useState} from "react";
import {PaymentDetails} from "declerations/server";
import {PaymentType} from "declerations/enums";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {useTask, useUser} from "contexts";
import {useGetUserWithSelectedAccountQuery} from "apis/requests/backoffice";
import {useGetUserQuery} from "apis/requests/user/getUser";
import NiceModal from "@ebay/nice-modal-react";
import BlockedACHDialog from "./BlockedACHDialog";
import {Chip, ChipType, Colors, Container, ImgBillingTree, ImgPci, LoadingCenter} from "bounce-ui/consumer";
import PaymentFormWrapper from ".//PaymentFormWrapper";
import {Segmented} from "antd";
import {getUserFullName} from "../../../../backoffice/information-panel/utils";
import AchPaymentForm from "./AchPaymentForm";
import DebitPaymentForm from "./DebitPaymentForm";
import {isUndefined} from "lodash";
import {Label} from "../../../account/utils-components";

interface PaymentTabPanelProps {
  onCardSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  onAchSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  setIsSupervisorView?: (i: boolean) => void;
  closePaymentDialogHandler?: () => void;
  submitLabelId?: string;
  termsAndConditionsId?: string;
  termsAndConditionsTextId?: string;
  isDashboardDialog?: boolean;
  sx?: SxProps<Theme>;
}

export const PaymentsImages = () => (
  <Container fullWidth row sx={{justifyContent: "center", gap: "25px", padding: "10px"}}>
    <ImgBillingTree />
    <ImgPci />
  </Container>
);

const getChipLabel = (isAgent: boolean, achAllowed: boolean | undefined) => {
  if (isAgent && !achAllowed) {
    return {label: "Supervisor", variant: "warning"};
  }
  if (achAllowed) {
    return {label: "Popular", variant: "primary"};
  }
  return {label: null, variant: null};
};

const PaymentTabs: FunctionComponent<PaymentTabPanelProps> = ({
  onCardSubmit,
  onAchSubmit,
  submitLabelId,
  termsAndConditionsId,
  termsAndConditionsTextId,
  isDashboardDialog = false,
  setIsSupervisorView,
  closePaymentDialogHandler,
}: PaymentTabPanelProps) => {
  const {getBoUserId} = useTask();
  const userId = getBoUserId();
  const {isAgent} = useUser();
  const {data: user} = useGetUserQuery({});
  const {data: userDetails} = useGetUserWithSelectedAccountQuery({userId}, {enabled: !!userId});
  const userName = isAgent && userDetails ? getUserFullName(userDetails) : user?.name;
  const [paymentMethod, setPaymentMethod] = useState<PaymentType>(user?.ach_allowed ? "ACH" : "DebitCard");
  const achAllowed = useMemo(() => {
    return isAgent ? userDetails && userDetails.ach_allowed : user && user.ach_allowed;
  }, [user, userDetails]);
  const {label, variant} = getChipLabel(isAgent, achAllowed);

  const onChangeTab = () => {
    if (paymentMethod === "ACH") {
      setPaymentMethod("DebitCard");
    } else {
      if (achAllowed) {
        setPaymentMethod("ACH");
      } else {
        NiceModal.show(BlockedACHDialog, {
          setAchActiveTab: () => setPaymentMethod("ACH"),
          secondaryButtonText: isAgent ? "approved_by_supervisor" : "contact_us",
          isAgent,
          setIsSupervisorView,
          closePaymentDialogHandler,
        });
      }
    }
  };

  const primaryEmail: string = useMemo(() => {
    if (isAgent && userDetails?.representation) {
      return userDetails?.representation.email || "";
    } else if (user?.represented) {
      return user.representation_email || "";
    }
    return user?.email || userDetails?.email || "";
  }, [userDetails, user]);

  if (userId && !userDetails && isAgent && isUndefined(achAllowed)) {
    return <LoadingCenter />;
  }
  return (
    <PaymentFormWrapper isDashboardDialog={isDashboardDialog}>
      <Segmented
        value={paymentMethod}
        onChange={onChangeTab}
        size={"large"}
        style={{padding: "4px", width: "100%", backgroundColor: Colors.tertiary._200}}
        options={[
          {
            label: (
              <Label label={"ACH"}>
                {label && variant && <Chip label={label} variant_={variant as ChipType["variant_"]} border />}
              </Label>
            ),
            value: "ACH",
          },
          {
            label: <Label label={"Debit card"} />,
            value: "DebitCard",
          },
        ]}
        block
      />
      {paymentMethod === "ACH" && (
        <AchPaymentForm
          onAchSubmit={onAchSubmit}
          submitLabelId={submitLabelId}
          termsAndConditionsId={termsAndConditionsId}
          termsAndConditionsTextId={termsAndConditionsTextId}
          isChangePaymentMethod={isDashboardDialog}
          primaryEmail={primaryEmail}
          userName={userName || ""}
        />
      )}
      {paymentMethod === "DebitCard" && (
        <DebitPaymentForm
          type={"DebitCard"}
          onCardSubmit={onCardSubmit}
          submitLabelId={submitLabelId}
          termsAndConditionsId={termsAndConditionsId}
          termsAndConditionsTextId={termsAndConditionsTextId}
          isChangePaymentMethod={isDashboardDialog}
          primaryEmail={primaryEmail}
          userName={userName || ""}
        />
      )}
    </PaymentFormWrapper>
  );
};
export default PaymentTabs;
