import {UrlInfo} from "../declerations/internal";
import {findLast} from "lodash";
import {matchPath} from "react-router-dom";

function asUrlInfos<T extends {[key: string]: UrlInfo}>(arg: T): T {
  return arg;
}

export const urls = asUrlInfos({
  // Error Root
  error: {
    url: "/error",
    path: "error",
    titleKey: "Error",
  },
  spring: {
    url: "/spring",
    path: "spring",
    titleKey: "Spring",
  },
  // Backoffice Root
  backoffice: {
    url: "/backoffice",
    path: "backoffice",
    titleKey: "Backoffice",
  },
  search: {
    url: "/backoffice/search-panel",
    path: "search-panel",
    titleKey: "Search panel",
  },
  history: {
    url: "/backoffice/history",
    path: "history",
    titleKey: "History",
  },
  targetBreakdown: {
    url: "/backoffice/target-breakdown",
    path: "target-breakdown",
    titleKey: "Target breakdown",
  },
  informationPanel: {
    url: "/backoffice/information-panel",
    path: "information-panel",
    titleKey: "Information panel",
  },
  backofficeLogin: {
    url: "/backoffice/login",
    path: "backoffice/login",
    titleKey: "Backoffice login",
  },
  // Auth Root
  auth: {
    url: "/auth",
    path: "auth",
    titleKey: "Login",
  },
  login: {
    url: "/auth/login",
    path: "login",
    titleKey: "Login",
  },
  deprecatedLogin: {
    url: "/auth/login/:userId",
    path: "login/:userId",
    titleKey: "Login",
  },
  verifyAccount: {
    url: "/auth/verify-account/:userId",
    path: "verify-account/:userId",
    titleKey: "Verify account",
  },
  verifyAnonymousAccount: {
    url: "/auth/verify-account",
    path: "verify-account",
    titleKey: "Verify account",
  },
  verifyCode: {
    url: "/auth/verify-code/:userId",
    path: "verify-code/:userId",
    titleKey: "Verify code",
  },
  verifyCodeFromUrl: {
    url: "/auth/verify-code/:userId/:contactType/:contactDetail/:code",
    path: "verify-code/:userId/:contactType/:contactDetail/:code",
    titleKey: "Verify code",
  },
  verifyCodeFromUrlWithCampaign: {
    url: "/auth/verify-code/:userId/:contactType/:contactDetail/:campaign/:code",
    path: "verify-code/:userId/:contactType/:contactDetail/:campaign/:code",
    titleKey: "Verify code",
  },
  // Account Root
  account: {
    url: "/account",
    path: "account",
    titleKey: "Account information",
  },
  accountMain: {
    url: "/account/:accountId",
    path: ":accountId",
    titleKey: "Account",
  },
  accounts: {
    url: "/accounts",
    path: "accounts",
    titleKey: "Accounts",
  },
  accountDashboard: {
    url: "/account/:accountId/account-dashboard",
    path: ":accountId/account-dashboard",
    titleKey: "Dashboard",
  },
  dispute: {
    url: "/account/:accountId/dispute",
    path: ":accountId/dispute",
    titleKey: "Dispute",
  },
  disputeSuccess: {
    url: "/account/:accountId/dispute-success",
    path: ":accountId/dispute-success",
    titleKey: "Dispute success",
  },
  presetPlanCheckout: {
    url: "/account/:accountId/checkout/plan",
    path: ":accountId/checkout/plan",
    titleKey: "Checkout",
  },
  paymentSuccess: {
    url: "/account/:accountId/payment-success",
    path: ":accountId/payment-success",
    titleKey: "Payment success",
  },
  calendarReminders: {
    url: "/account/:accountId/calendar-reminders",
    path: ":accountId/calendar-reminders",
    titleKey: "Calendar reminders",
  },
  // Payment plan
  choosePlan: {
    url: "/account/:accountId/choose-plan",
    path: ":accountId/choose-plan",
    titleKey: "Choose plan",
  },
  summaryPage: {
    url: "/account/:accountId/summary",
    path: ":accountId/summary",
    titleKey: "Summary",
  },
  partialPayment: {
    url: "/account/:accountId/partial-payment",
    path: ":accountId/partial-payment",
    titleKey: "Checkout",
  },
  guestPaymentPlanRoot: {
    url: "/guest-checkout",
    path: "guest-checkout",
    titleKey: "Checkout",
  },
  guestPaymentPlanCheckout: {
    url: "/guest-checkout/:paymentPlanId",
    path: ":paymentPlanId",
    titleKey: "Checkout",
  },
  guestPaymentRoot: {
    url: "/guest-payment",
    path: "guest-payment",
    titleKey: "Guest payment",
  },
  guestPaymentCheckout: {
    url: "/guest-payment/:token",
    path: ":token",
    titleKey: "Guest payment",
  },
  guestPaymentSuccess: {
    url: "/guest-checkout/payment-success",
    path: "payment-success",
    titleKey: "Payment success",
  },
  hardshipIntro: {
    url: "/account/:accountId/hardship-intro",
    path: ":accountId/hardship-intro",
    titleKey: "Hardship intro",
  },
  hardshipPlans: {
    url: "/account/:accountId/hardship-plans",
    path: ":accountId/hardship-plans",
    titleKey: "Hardship plans",
  },
  reactivatePlan: {
    url: "/account/:accountId/reactivate-plan",
    path: ":accountId/reactivate-plan",
    titleKey: "Reactivate plan",
  },
  taxRefundWizard: {
    url: "/account/:accountId/tax-refund-wizard",
    path: ":accountId/tax-refund-wizard",
    titleKey: "Tax refund wizard",
  },
  // Spring urls
  // Debt Settlement:
  dscBulkOperations: {
    url: "/spring/debt-settlement/bulk-operations",
    path: "debt-settlement/bulk-operations",
    titleKey: "Bulk operations",
  },
  anonymousCommunications: {
    url: "/spring/debt-settlement/anonymous-communications",
    path: "debt-settlement/anonymous-communications",
    titleKey: "Anonymous communication",
  },
  representationCompanies: {
    url: "/spring/debt-settlement/representation-companies",
    path: "debt-settlement/representation-companies",
    titleKey: "Representation companies",
  },
  // Lender Operations:
  importPricing: {
    url: "/spring/lender-operations/import-pricing",
    path: "lender-operations/import-pricing",
    titleKey: "Import pricing",
  },
  generatePricing: {
    url: "/spring/lender-operations/generate-pricing",
    path: "lender-operations/generate-pricing",
    titleKey: "Generate pricing",
  },
  importUsers: {
    url: "/spring/lender-operations/import-users",
    path: "lender-operations/import-users",
    titleKey: "Import users",
  },
  importPayments: {
    url: "/spring/lender-operations/import-payments",
    path: "lender-operations/import-payments",
    titleKey: "Import payments",
  },
  washing: {
    url: "/spring/lender-operations/washing",
    path: "lender-operations/washing",
    titleKey: "Washing",
  },
  userLevelMedia: {
    url: "/spring/lender-operations/user-level-media",
    path: "lender-operations/user-level-media",
    titleKey: "User level media",
  },
  batchLevelMedia: {
    url: "/spring/lender-operations/batch-level-media",
    path: "lender-operations/batch-level-media",
    titleKey: "Batch level media",
  },
  // Search
  searchSpring: {
    url: "/spring/search",
    path: "search",
    titleKey: "Search",
  },
  informationPanelSpring: {
    url: "/spring/information-panel",
    path: "information-panel",
    titleKey: "Information panel",
  },
  // Call center
  rexManagement: {
    url: "/spring/call-center/rex-management",
    path: "call-center/rex-management",
    titleKey: "Rex management",
  },
  conversationsSpring: {
    url: "/spring/conversations",
    path: "conversations",
    titleKey: "Conversations",
  },
  // Bankruptcies
  bankruptcies: {
    url: "/spring/bankruptcies",
    path: "bankruptcies",
    titleKey: "Bankruptcies",
  },
  inventory: {
    url: "/spring/inventory",
    path: "inventory",
    titleKey: "Inventory",
  },
  litigationBulkOperation: {
    url: "/spring/litigation/bulk-operation",
    path: "litigation/bulk-operation",
    titleKey: "Litigation Bulk Operation",
  },
});

export const getUrlInfo = (pathname: string): UrlInfo | undefined => {
  return findLast(urls, (url) => !!matchPath({path: url.url}, pathname));
};
