import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "@bounce/shared/requests";
import {AccountFlags} from "../../../constants/enums";
import {CommentToUpdate} from "declerations/server/comment_models";

type Variables = {
  userId: string;
  accountFlags?: Map<AccountFlags, boolean>;
  accountId: string;
  accountComments?: CommentToUpdate[];
};
type Data = boolean;

export const updateAccount = async (variables: Variables): Promise<Data> => {
  await axiosFlexInstance.patch<string, AxiosResponse<number>>(`/accounts/${variables.accountId}`, {
    account_flags: variables.accountFlags ? Object.fromEntries(variables.accountFlags) : {},
    comments: variables.accountComments,
  });
  return true;
};

export const useUpdateAccount = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(updateAccount, {
    ...options,
    prefixKeysToInvalidate: (data, variables) => [["user"], ["user", variables.userId, "timeline"]],
  });
