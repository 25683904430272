import {ChannelType, EventType} from "../../../../enums";
import {InputUtils} from "../../../../helpers";
import {ActivityData, IActivityFilter, IChannelToEventOptions} from "../../../../declarations";
import {filter, some} from "lodash";
import {EventTypeMap} from "../utils";

const buildFilters = (
  principalsOptions: string[],
  excludeEventTypes: string[],
  ChannelToEventOptions: IChannelToEventOptions
): IActivityFilter => {
  const includedEventTypes = Object.values(ChannelToEventOptions).flatMap((array) =>
    array.filter((value) => !excludeEventTypes.includes(value))
  );
  return {
    eventType: includedEventTypes,
    principal: principalsOptions,
  };
};

const getClearedFilter = (principalsOptions: string[]): IActivityFilter => {
  const clearEventType: string[] = [];
  return {
    eventType: clearEventType,
    principal: principalsOptions,
  };
};

const getEventLabel = (channel: ChannelType, event: EventType) => {
  if (["user_activity", "account_update"].includes(channel)) {
    return InputUtils.prettierSnakeCase(event);
  }
  return EventTypeMap.get(event as EventType)?.labelKey;
};

const applyFilter = (
  defaultPrincipalFilter: string[],
  filters: IActivityFilter,
  timelineActivities: ActivityData[]
): ActivityData[] => {
  const filteredByEventType =
    filters.eventType.length === 0
      ? timelineActivities
      : filter(timelineActivities, (activity) =>
          some(filters.eventType, (type) => {
            return type === activity.event_type;
          })
        );

  return filter(filteredByEventType, (activity) =>
    some(filters.principal, (type) => {
      if (type === "Others") {
        return !defaultPrincipalFilter.includes(activity.principal);
      }
      if (type === "System") {
        return !activity.principal || type === activity.principal;
      } else {
        return type === activity.principal;
      }
    })
  );
};

export {buildFilters, getClearedFilter, getEventLabel, applyFilter};
