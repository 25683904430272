import React from "react";
import {IContainerProps} from "@bounce/shared/ui";
import useIsMobile from "../../helpers/useIsMobile";
import {Card} from "./Card";
import {Colors} from "@bounce/shared/ui";

interface IMapColorToTheme {
  color?: "purple" | "yellow" | "red" | "green";
  promotion?: "new_years" | "tax_refund" | "black_friday" | undefined;
}

interface INotificationCard extends IContainerProps, IMapColorToTheme {}

const mapColorToTheme = {
  purple: Colors.secondary._100,
  yellow: Colors.orange._100,
  red: Colors.red._100,
  green: Colors.primary._100,
  black: Colors.grey._1000,
};

const mapPromotionToTheme = {
  new_years: Colors.primary._100,
  tax_refund: Colors.neon._100,
  black_friday: Colors.grey._1000,
};

export const NotificationCard = ({color = "purple", promotion, children, sx}: INotificationCard) => {
  const isMobile = useIsMobile();
  return (
    <Card
      sx={{
        boxShadow: "none",
        backgroundColor: promotion ? mapPromotionToTheme[promotion] : mapColorToTheme[color],
        gap: "12px",
        width: isMobile ? "100%" : "530px",
        padding: "12px",
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};
