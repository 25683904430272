import React from "react";
import {Checkbox as MuiCheckbox, CheckboxProps, FormControlLabel as MuiFormControlLabel} from "@mui/material";
import {
  IconCheckboxEmpty,
  IconCheckboxNeutral,
  IconCheckboxPrimary,
  IconCheckboxSecondary,
  IconCheckboxTertiary,
} from "../assets";
import {Colors} from "../colors";

export type CheckboxType = CheckboxProps & {color?: "primary" | "secondary" | "default" | "info"};

export const FormControlLabel = MuiFormControlLabel;

export const mapCheckedIcon = {
  primary: IconCheckboxPrimary,
  secondary: IconCheckboxSecondary,
  default: IconCheckboxNeutral,
  info: IconCheckboxTertiary,
};

const Size = {
  small: "16px",
  medium: "20px",
  large: "24px",
};

export const Checkbox = ({color = "primary", size = "small", sx, ...props}: CheckboxType) => {
  const Icon = mapCheckedIcon[color];
  return (
    <MuiCheckbox
      icon={<IconCheckboxEmpty width={Size[size]} height={Size[size]} />}
      checkedIcon={<Icon width={Size[size]} height={Size[size]} />}
      sx={{
        "&.Mui-checked": {
          ...(color === "secondary" && {color: Colors.grey._1000}),
        },
        margin: "2px 6px",
        padding: "0px",
        ...sx,
      }}
      {...props}
    />
  );
};
