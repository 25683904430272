import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "@bounce/shared/requests";
import {ActivityData} from "@bounce/shared/declarations";

type Variables = {
  userId: string;
};

type Data = ActivityData[];
export const getUserTimeline = async (variables: Variables): Promise<Data> => {
  const result = await axiosFlexInstance.get<Data, AxiosResponse<Data>>(`/user/timeline/${variables.userId}`);
  return result.data;
};

export const useGetUserTimeline = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  return useBaseQuery<Data, Variables>(["user", variables.userId, "timeline"], getUserTimeline, variables, options);
};
