import React from "react";
import {Container, IContainerProps} from "@bounce/shared/ui";
import {Colors} from "@bounce/shared/ui";

export const Card = ({children, row, onClick, className = "Card", sx}: IContainerProps) => {
  return (
    <Container
      className={className}
      fullWidth
      row={row}
      onClick={onClick}
      sx={{
        backgroundColor: Colors.bw.white,
        boxShadow: (theme) => theme.customShadows.cardV2,
        borderRadius: "10px",
        padding: "25px 50px",
        ...sx,
      }}
    >
      {children}
    </Container>
  );
};
