import React, {FunctionComponent, useEffect} from "react";
import NiceModal, {NiceModalHocProps} from "@ebay/nice-modal-react";
import {Principals, CategoryWithSubCategories} from "./Category";
import {useActivityFilter} from "./useActivityFilter";
import {IActivityFilter, IChannelToEventOptions} from "../../../../declarations";
import {Container} from "../../Container";
import {Button} from "../../Button";
import {Dialog} from "../../Dialog";

interface IFilterDialog {
  setSelectedFilters: (value: IActivityFilter) => void;
  selectedFilters: IActivityFilter;
  defaultFilters?: IActivityFilter;
  allFilters: IActivityFilter;
  clearedFilters: IActivityFilter;
  isFilterByPrincipal: boolean;
  ChannelToEventOptions: IChannelToEventOptions;
}

export const FilterDialog: FunctionComponent<IFilterDialog & NiceModalHocProps> = NiceModal.create(
  ({
    setSelectedFilters,
    selectedFilters,
    defaultFilters,
    allFilters,
    clearedFilters,
    isFilterByPrincipal = true,
    ChannelToEventOptions,
  }) => {
    const {filters, setFilters, updateFilter} = useActivityFilter();

    const handleSubmit = () => {
      setSelectedFilters(filters);
    };

    useEffect(() => {
      const currentFilters: IActivityFilter = {
        eventType: [...selectedFilters.eventType],
        principal: [...selectedFilters.principal],
      };
      setFilters(currentFilters);
    }, [selectedFilters]);

    return (
      <Dialog title={"Filter by activity type"} onClick={handleSubmit} buttonText={"Apply"}>
        <Container
          fullWidth
          sx={{
            alignItems: "start",
            height: "400px",
            gap: "20px",
            overflow: "auto",
            justifyContent: "space-between",
          }}
        >
          <Container fullWidth sx={{padding: "5px 5px 40px 5px", gap: "15px", flex: 1, overflow: "auto"}}>
            <Container row fullWidth sx={{alignItems: "start", gap: "8px"}}>
              <Button buttonType={"outlined-primary"} onClick={() => setFilters(allFilters)} size={"small"}>
                Select all
              </Button>
              <Button buttonType={"outlined-primary"} onClick={() => setFilters(clearedFilters)} size={"small"}>
                Clear
              </Button>
              {defaultFilters && (
                <Button buttonType={"outlined-primary"} onClick={() => setFilters(defaultFilters)} size={"small"}>
                  Reset to default
                </Button>
              )}
            </Container>
            <CategoryWithSubCategories
              category={"activityType"}
              options={ChannelToEventOptions}
              updateFilter={updateFilter}
              filters={filters}
            />
            {isFilterByPrincipal && (
              <Principals
                category={"principal"}
                options={allFilters.principal}
                updateFilter={updateFilter}
                filters={filters}
              />
            )}
          </Container>
        </Container>
      </Dialog>
    );
  }
);
