import {Theme} from "@mui/material/styles";
import {Colors} from "../../colors";

// ----------------------------------------------------------------------

export default function ToggleButton(theme: Theme) {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          color: Colors.primary._800,
          border: `solid 1px ${Colors.primary._800}`,
          "&:hover": {
            backgroundColor: Colors.grey._100,
          },
          "&.Mui-selected": {
            color: Colors.bw.white,
            backgroundColor: Colors.primary._800,
            "&:hover": {
              backgroundColor: Colors.primary._800,
            },
          },
          "&.Mui-disabled": {
            color: Colors.grey._400,
          },
        },
      },
    },
  };
}
