import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {Content} from "../../../declerations/server";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "@bounce/shared/requests";

type Variables = {
  user_id: string;
  account_id: string;
  contact?: string;
  date?: string;
  content: Content;
  principal: string | undefined;
};

type Data = boolean;

export const sendArbitraryContent = async (variables: Variables): Promise<Data> => {
  await axiosFlexInstance.post<string, AxiosResponse<string>>(`/send_arbitrary_content/${variables.user_id}`, {
    account_id: variables.account_id,
    content: variables.content,
    contact: variables.contact,
    date: variables.date,
    run_police: true,
    source: "backoffice",
  });
  return true;
};

export const useSendArbitraryContent = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(sendArbitraryContent, {
    ...options,
    prefixKeysToInvalidate: (data, variables) => [
      ["user", variables.user_id, "timeline"],
      [variables.principal || "default", "scheduled"],
    ],
  });
