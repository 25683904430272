import React from "react";
import {Colors} from "../colors";

export const BounceLogo = ({
  color = Colors.primary._800,
  height = "200px",
  width = "40px",
}: {
  color?: string;
  height?: string;
  width?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 152 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.957 3.8577C115.925 3.8577 103.739 16.0438 103.739 31.076H99.8815C99.8815 22.1987 92.6851 15.0022 83.8078 15.0022C74.9305 15.0022 67.7341 22.1987 67.7341 31.076H63.8764C63.8764 20.0681 72.8 11.1445 83.8078 11.1445C91.3884 11.1445 97.9805 15.3765 101.351 21.6068C105.355 9.07461 117.097 0 130.957 0V3.8577Z"
        fill={color}
      />
      <path
        d="M121.7 31.568C116.041 31.568 111.753 27.2448 111.753 21.586C111.753 15.9272 116.041 11.6391 121.7 11.6391C126.41 11.6391 130.065 14.5213 131.12 18.8796H127.253C126.269 16.7004 124.16 15.3648 121.7 15.3648C118.291 15.3648 115.725 18.0712 115.725 21.586C115.725 25.1008 118.326 27.8423 121.7 27.8423C124.196 27.8423 126.199 26.5067 127.218 24.1869H131.155C130.136 28.6156 126.375 31.568 121.7 31.568Z"
        fill={color}
      />
      <path
        d="M152 21.6563C152 22.078 151.965 22.4998 151.895 22.9216H136.5C136.992 26.0146 139.206 27.9126 142.299 27.9126C144.584 27.9126 146.412 26.8582 147.431 25.1008H151.543C150.032 29.1076 146.552 31.568 142.299 31.568C136.746 31.568 132.563 27.2799 132.563 21.586C132.563 15.9272 136.746 11.6391 142.299 11.6391C148.134 11.6391 152 16.1381 152 21.6563ZM142.299 15.1891C139.382 15.1891 137.238 16.9465 136.605 19.7583H148.063C147.36 16.8762 145.216 15.1891 142.299 15.1891Z"
        fill={color}
      />
      <path
        d="M10.1226 11.6391C15.8869 11.6391 19.9992 15.892 19.9992 21.6211C19.9992 27.2799 15.8869 31.568 10.1226 31.568C7.4865 31.568 5.13159 30.5838 3.44449 28.9319L2.95242 31.1111H0V5.3125H3.90142V13.8534C5.55337 12.4475 7.69739 11.6391 10.1226 11.6391ZM9.84141 27.8072C13.4265 27.8072 16.0274 25.1711 16.0274 21.586C16.0274 18.036 13.4265 15.3648 9.84141 15.3648C6.25633 15.3648 3.62023 18.036 3.62023 21.586C3.62023 25.1359 6.25633 27.8072 9.84141 27.8072Z"
        fill={color}
      />
      <path
        d="M31.8959 31.568C26.0613 31.568 21.7382 27.3151 21.7382 21.586C21.7382 15.8569 26.0613 11.6391 31.8959 11.6391C37.6953 11.6391 42.0185 15.892 42.0185 21.586C42.0185 27.3151 37.6953 31.568 31.8959 31.568ZM31.8959 27.8423C35.481 27.8423 38.0468 25.2414 38.0468 21.586C38.0468 17.9657 35.481 15.3648 31.8959 15.3648C28.2757 15.3648 25.7099 17.9657 25.7099 21.586C25.7099 25.2414 28.2757 27.8423 31.8959 27.8423Z"
        fill={color}
      />
      <path
        d="M53.4855 31.568C48.2485 31.568 44.8391 28.1938 44.8391 23.0622V12.096H48.7405V22.535C48.7405 25.8389 50.5331 27.8423 53.4855 27.8423C56.4379 27.8423 58.1953 25.8037 58.1953 22.535V12.096H62.0967V23.0622C62.0967 28.1938 58.7225 31.568 53.4855 31.568Z"
        fill={color}
      />
    </svg>
  );
};
