import {Colors, Container, IconCheckbox, Text} from "bounce-ui/consumer";
import React, {ReactNode} from "react";

const Label = ({label, children}: {label: string; children?: ReactNode}) => (
  <Container row sx={{justifyContent: "center", height: "40px"}}>
    <Text variant={"body3"} size={14}>
      {label}
    </Text>
    {children}
  </Container>
);

const CheckboxLabel = ({label}: {label: string}) => (
  <Container row sx={{gap: "4px"}}>
    <IconCheckbox />
    <Text variant={"body3"} size={12}>
      {label}
    </Text>
  </Container>
);

const TaxSeasonPromo = () => (
  <Text variant={"h4"} size={14} color={"secondary"} sx={{display: "inline"}}>
    TAX SEASON{" "}
    <Text variant={"h4"} size={14} sx={{display: "inline", color: Colors.neon._800}}>
      PROMO
    </Text>
  </Text>
);

export {Label, CheckboxLabel, TaxSeasonPromo};
