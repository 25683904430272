import {FunctionComponent} from "react";
import {Text, Container, Colors} from "@bounce/shared/ui";

interface UserDetailProps {
  detailName: string;
  detail?: string;
}

const UserDetail: FunctionComponent<UserDetailProps> = ({detailName, detail}) => {
  return (
    <Container row>
      <Text size={13} sx={{whiteSpace: "nowrap", color: Colors.grey._800}}>{`${detailName}: `}</Text>
      <Text
        size={13}
        sx={{
          width: "150px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {detail}
      </Text>
    </Container>
  );
};

export default UserDetail;
