import React, {FunctionComponent, ReactNode} from "react";
import {Dialog as MuiDialog} from "@mui/material";
import {useModal} from "@ebay/nice-modal-react";
import {InputUtils} from "@bounce/shared/helpers";
import {Button} from "./Button";
import {CloseDialogButton} from "./CloseDialogButton";
import {Container} from "./Container";
import {Text} from "./Text";

export type CustomDialogType = {
  title: string;
  forceTitle?: boolean;
  onClick: () => void;
  onClose?: () => void;
  buttonText?: string;
  secondaryButtonText?: string;
  onClickSecondary?: () => void;
  isError?: boolean;
  width?: string;
  alignCenter?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  noButton?: boolean;
};

export const CustomDialog: FunctionComponent<CustomDialogType> = ({
  title,
  buttonText = "Save",
  onClick,
  onClose,
  secondaryButtonText,
  onClickSecondary,
  alignCenter = false,
  width,
  isError,
  disabled = false,
  children,
  forceTitle = false,
  noButton = false,
}) => {
  const modal = useModal();
  const onClickModal = async () => {
    onClick();
    modal.resolve(true);
    modal.hide();
  };

  const onClickSecondaryModal = async () => {
    onClickSecondary?.();
    modal.resolve(false);
    modal.hide();
  };

  return (
    <MuiDialog
      open={modal.visible}
      TransitionProps={{
        onExited: () => {
          onClose?.();
          modal.remove();
        },
      }}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",
          padding: "44px",
          gap: "24px",
        },
      }}
    >
      <CloseDialogButton onClose={modal.hide} />
      <Container sx={{gap: "24px", width: width || "300px", alignItems: alignCenter ? "center" : "start"}}>
        <Text variant={"h3"} size={24}>
          {forceTitle ? title : InputUtils.prettierSnakeCaseSentence(title)}
        </Text>
        {children}
      </Container>
      <Container sx={{gap: "8px"}}>
        {!noButton && (
          <Button
            buttonType={isError ? "contained-error" : "contained-primary"}
            onClick={onClickModal}
            disabled={disabled}
            fullWidth
          >
            {InputUtils.prettierSnakeCaseSentence(buttonText)}
          </Button>
        )}
        {secondaryButtonText && (
          <Button
            buttonType={isError ? "outlined-secondary" : "contained-secondary"}
            onClick={onClickSecondaryModal}
            fullWidth
          >
            {InputUtils.prettierSnakeCaseSentence(secondaryButtonText)}
          </Button>
        )}
      </Container>
    </MuiDialog>
  );
};
