import React, {useRef} from "react";
import Lottie from "lottie-react";
import {Container} from "@bounce/shared/ui";
import {AnimatedBounce} from "../../assets";

const LottieBounce = ({handleComplete}: {handleComplete: () => void}) => {
  const lottieRef = useRef(null);

  return (
    <Container
      sx={{
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
      }}
    >
      <div style={{width: "400px"}}>
        <Lottie animationData={AnimatedBounce} loop={false} lottieRef={lottieRef} onComplete={handleComplete} />
      </div>
    </Container>
  );
};

export {LottieBounce};
