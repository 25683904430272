import React from "react";
import {Container} from "../../Container";
import {Text} from "../../Text";
import {Divider} from "../../Divider";
import {Checkbox} from "../../Checkbox";
import {InputUtils} from "../../../../helpers";
import {map} from "lodash";
import {getEventLabel} from "./utils";
import {ChannelType, EventType} from "../../../../enums";
import {IActivityFilter, IChannelToEventOptions} from "../../../../declarations";
import {getNameFromEmail} from "../utils";
import {FormControlLabel} from "@mui/material";

interface ICategory {
  category: string;
  updateFilter: (check: boolean, value: string, category: string) => void;
  filters: IActivityFilter;
}

interface IArrayCategory extends ICategory {
  options: string[];
}

interface IMapCategory extends ICategory {
  options: IChannelToEventOptions;
}

export const CategoryWithSubCategories = ({category, options, updateFilter, filters}: IMapCategory) => {
  const handleClick = (filter: string) => (event: any) => {
    updateFilter(event.target.checked, filter, category);
  };

  return (
    <Container fullWidth sx={{alignItems: "start", gap: "15px"}}>
      <Container fullWidth sx={{alignItems: "start", gap: 0}}>
        {map(Object.entries(options), ([channel, events]) => {
          return (
            <Container key={channel} fullWidth sx={{alignItems: "start"}}>
              <Divider sx={{width: "100%"}} />
              <Text size={12} fontWeight={"medium"} sx={{textTransform: "uppercase"}}>
                {InputUtils.prettierSnakeCase(channel)}
              </Text>
              {events && (
                <Container fullWidth sx={{alignItems: "start", gap: "0px"}}>
                  {map(events, (event) => {
                    const checked = filters.eventType.includes(event);
                    return (
                      <div key={event} style={{width: "100%"}}>
                        <Divider sx={{width: "100%"}} />
                        <FormControlLabel
                          control={<Checkbox color={"default"} checked={checked} onClick={handleClick(event)} />}
                          label={<Text size={14}>{getEventLabel(channel as ChannelType, event as EventType)}</Text>}
                        />
                      </div>
                    );
                  })}
                </Container>
              )}
            </Container>
          );
        })}
      </Container>
    </Container>
  );
};

export const Principals = ({category, options, updateFilter, filters}: IArrayCategory) => {
  const handleClick = (filter: string) => (event: any) => {
    updateFilter(event.target.checked, filter, category);
  };

  return (
    <Container fullWidth sx={{alignItems: "start", gap: "15px"}}>
      <Text size={18} fontWeight={"bold"}>
        Filter by principal
      </Text>
      <Container fullWidth sx={{alignItems: "start", gap: 0}}>
        {map(options, (option, index) => {
          const checked = filters.principal.includes(option);
          return (
            <div key={index} style={{width: "100%"}}>
              <Divider sx={{width: "100%"}} />
              <FormControlLabel
                control={<Checkbox checked={checked} onClick={handleClick(option)} />}
                label={
                  <Text size={14} sx={{textTransform: "capitalize"}}>
                    {getNameFromEmail(option)}
                  </Text>
                }
              />
            </div>
          );
        })}
      </Container>
    </Container>
  );
};
