import {experimentalStyled as styled} from "@mui/material/styles";
import {AppBar, Box, Toolbar} from "@mui/material";
import {NAVBAR_DESKTOP_HEIGHT, NAVBAR_MOBILE_HEIGHT} from "../../constants/ui";
import MainMenu from "./menu/MainMenu";
import {useUser, useUtils} from "../../contexts";
import React, {FunctionComponent} from "react";
import {BounceLogo, Colors} from "bounce-ui/consumer";
import LanguageSwitcher from "../../LanguageSwitcher";

const RootStyle = styled(AppBar)(({theme}) => ({
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  zIndex: "100",
}));

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
  boxShadow: theme.customShadows.header,
  backgroundColor: Colors.bw.white,
  minHeight: NAVBAR_MOBILE_HEIGHT,
  [theme.breakpoints.up("lg")]: {
    minHeight: NAVBAR_DESKTOP_HEIGHT,
  },
}));

const AccountHeader: FunctionComponent = () => {
  const {loggedIn, userId} = useUser();
  const {navigate} = useUtils();
  return (
    <RootStyle>
      <ToolbarStyle variant={"dense"}>
        {loggedIn && !!userId && <MainMenu />}
        <Box sx={{flexGrow: 1}} />
        <Box
          onClick={() => navigate("/")}
          sx={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", cursor: "pointer"}}
        >
          <BounceLogo width={"100px"} height={"21px"} />
        </Box>
        {window.Weglot && <LanguageSwitcher />}
      </ToolbarStyle>
    </RootStyle>
  );
};

export default AccountHeader;
