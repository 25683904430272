import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {useLocalStorageState} from "../hooks/useLocalStorageState";
import {getLocale} from "../helpers/LocaleUtils";
import {LocaleInfo} from "../declerations/internal";
import locales from "../lang";
import {useGetUserContext} from "../apis/requests/user/getUserContext";
import {useLocation} from "react-router-dom";
import {PromotionsType} from "../declerations/server/account_models";

export interface UserContextProps {
  loggedIn: boolean;
  token?: string;
  userId?: string;
  publicUserId?: string;
  setCredentials: (credentials: UserCredentials) => void;
  /**
   * @deprecated This is a workaround for support login without a user id - please do not use this elsewhere!
   */
  setUserId: (userId: string) => void;
  clearCredentials: () => void;
  isMultiAccount?: boolean;
  setIsMultiAccount: (value: boolean) => void;
  locale: string;
  localeInfo: LocaleInfo;
  setLocale: (token: string) => void;
  approvedContactMethods: boolean;
  setApprovedContactMethods: (approved: boolean) => void;
  isAgent: boolean;
  agentEmail?: string;
  agentRole?: BounceRole;
  featureFlags: Record<string, boolean> | undefined;
  isAdmin: boolean;
  promotionFlag: PromotionsType;
}

export enum BounceRole {
  ADMIN = "admin",
  SUPERVISOR = "supervisor",
  REX = "rex",
  USER = "user",
}
export interface UserCredentials {
  token: string;
  userId?: string;
  agentEmail?: string;
  agentRole?: BounceRole;
}

const UserContext = React.createContext<UserContextProps>(undefined!);

interface IProps extends PropsWithChildren<any> {}

const getPromotionFlag = (featureFlags: Record<string, boolean | string>): PromotionsType => {
  if (featureFlags["NEW_YEARS"]) return "new_years";
  if (featureFlags["TAX_REFUND"] && featureFlags["TAX_REFUND"] !== "control") return "tax_refund";
};

const UserProvider: FunctionComponent<IProps> = ({children}) => {
  const {search} = useLocation();
  const queryParams = new URLSearchParams(search);
  const urlUserId = queryParams.get("id") || undefined;
  const [userId, setUserId] = useState<string>();
  const [publicUserId, setPublicUserId] = useState<string>();
  const [isMultiAccount, setIsMultiAccount] = useState<boolean>();
  const [featureFlags, setFeatureFlags] = useState<Record<string, boolean>>({});
  const [promotionFlag, setPromotionFlag] = useState<PromotionsType>();
  const [locale, setLocale] = useLocalStorageState<string>("locale", getLocale(), {encrypted: true});
  const [credentials, setCredentialsInternal] = useLocalStorageState<UserCredentials | undefined>(
    "credentials",
    undefined,
    {encrypted: true}
  );
  const [approvedContactMethods, setApprovedContactMethods] = useLocalStorageState<boolean>(
    "approvedContactMethods",
    false,
    {encrypted: true}
  );

  const localeInfo = useMemo<LocaleInfo>(() => locales[locale], [locale]);
  const loggedIn = useMemo<boolean>(() => !!credentials, [credentials]);
  const isAgent = useMemo<boolean>(
    () => !!credentials?.token && !credentials?.userId && !!credentials?.agentEmail,
    [credentials]
  );
  const token = useMemo<string | undefined>(() => credentials?.token, [credentials]);
  const agentEmail = useMemo<string | undefined>(() => credentials?.agentEmail, [credentials]);
  const agentRole = useMemo<BounceRole | undefined>(() => {
    return credentials?.agentRole;
  }, [credentials]);
  const isAdmin = useMemo<boolean>(() => {
    return agentRole !== undefined && [BounceRole.ADMIN, BounceRole.SUPERVISOR].includes(agentRole);
  }, [agentRole]);
  const setCredentials = useCallback(
    (credentials: UserCredentials): void => {
      setCredentialsInternal(credentials);
    },
    [setCredentialsInternal]
  );

  // urlUserId is to get the feature flags before initializing credentials
  const {data: userContextDetails} = useGetUserContext(
    {userId: userId || urlUserId},
    {enabled: !!userId || !!urlUserId}
  );

  useEffect(() => {
    if (credentials?.userId) {
      setUserId(credentials.userId);
    }
  }, [credentials]);

  useEffect(() => {
    if (userContextDetails) {
      setPublicUserId(userContextDetails.public_id);
      setFeatureFlags(userContextDetails.feature_flags);
    }
  }, [userContextDetails]);

  useEffect(() => {
    if (featureFlags) {
      const flag = getPromotionFlag(featureFlags);
      if (flag) {
        setPromotionFlag(flag);
      }
    }
  }, [featureFlags]);

  const clearCredentials = useCallback((): void => {
    setCredentialsInternal(undefined);
  }, [setCredentialsInternal]);

  return (
    <UserContext.Provider
      value={{
        loggedIn,
        token,
        agentEmail,
        agentRole,
        isAgent,
        userId,
        publicUserId,
        setUserId,
        isMultiAccount,
        setIsMultiAccount,
        setCredentials,
        clearCredentials,
        locale,
        localeInfo,
        setLocale,
        approvedContactMethods,
        setApprovedContactMethods,
        featureFlags,
        isAdmin,
        promotionFlag,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): UserContextProps => {
  const context = useContext(UserContext);

  if (!context) throw new Error("UserContext must be used inside UserProvider");

  return context;
};

export {UserContext, UserProvider, useUser};
