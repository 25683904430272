import React, {FunctionComponent, useMemo} from "react";
import merge from "lodash/merge";
import {styled, Theme} from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import BaseOptionChart from "./BaseOptionChart";
import {SxProps} from "@mui/system";

const CHART_HEIGHT = 120;

const ChartWrapperStyle = styled("div")(({theme}) => ({
  height: CHART_HEIGHT,
  "& .apexcharts-canvas svg": {height: CHART_HEIGHT},
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
}));

type ProgressCircleChartProps = {
  percent: number;
  sx?: SxProps<Theme>;
};

const ProgressCircleChart: FunctionComponent<ProgressCircleChartProps> = ({percent, sx}) => {
  const chartData = useMemo<
    {
      label: string;
      value: number;
    }[]
  >(() => [{label: "", value: percent}], [percent]);
  const chartColors = useMemo<string[][]>(() => [["#03542C", "#03542C"]], []);
  const chartLabels = useMemo<string[]>(() => chartData.map((i) => i.label), [chartData]);
  const chartSeries = useMemo<number[]>(() => chartData.map((i) => i.value), [chartData]);

  const overrideOptions = useMemo<Partial<ApexOptions>>(
    () => ({
      labels: chartLabels,
      legend: {show: false},
      fill: {
        type: "gradient",
        gradient: {
          colorStops: chartColors.map((colors) => [{offset: 0, color: colors[0]}]),
        },
      },
      chart: {
        height: CHART_HEIGHT,
        parentHeightOffset: 0,
      },
      stroke: {
        lineCap: "butt",
        width: 0,
      },
      grid: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },

      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          filter: {
            type: "none",
          },
        },
      },

      plotOptions: {
        radialBar: {
          hollow: {size: "35%"},
          track: {
            background: "#c8c8c8",
          },
          dataLabels: {
            name: {
              offsetY: 0,
            },
            value: {offsetY: -12, fontSize: "14px"},
            total: {
              label: "",
            },
          },
        },
      },
    }),
    [chartLabels, chartColors, percent]
  );

  const chartOptions: ApexOptions = merge(BaseOptionChart(), overrideOptions);

  return (
    <ChartWrapperStyle dir="ltr" sx={sx}>
      <ReactApexChart
        type="radialBar"
        series={chartSeries}
        options={chartOptions}
        height={CHART_HEIGHT}
        width={CHART_HEIGHT * 0.8}
      />
    </ChartWrapperStyle>
  );
};
export default ProgressCircleChart;
