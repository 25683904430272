import React, {FunctionComponent} from "react";
import NiceModal, {NiceModalHocProps} from "@ebay/nice-modal-react";
import {Container, Text, Dialog, LoadingCenter} from "bounce-ui/consumer";
import {copyToClipboard} from "../../../backoffice/information-panel/utils";
import {useUserMetaData, useUtils} from "contexts";
import ContributionLink from "./ContributionLink";
import {useFetchRequest} from "@bounce/shared/helpers";
import {ContributionLinkResponse} from "../../../../declerations/server/payment_plan_models";
import {getGuestContributionLink} from "../../../../apis/requests/files";

export interface GenerationContributionLinkDialogProps {
  guestLink: string;
  totalBalance: number;
  accountId: string;
}

const GenerateContributionLinkDialog: FunctionComponent<GenerationContributionLinkDialogProps & NiceModalHocProps> =
  NiceModal.create(({totalBalance, accountId}) => {
    const {enqueueSnackbar, isMobile} = useUtils();
    const {isAgent} = useUserMetaData();
    const {data, isLoading} = useFetchRequest<ContributionLinkResponse>(getGuestContributionLink, {
      accountId,
      isAgent,
    });

    const handleCopyLink = () => {
      if (data) {
        copyToClipboard(data.url, isMobile);
        enqueueSnackbar(<Text size={14}>Copied to clipboard successfully.</Text>, {variant: "success"});
      }
    };

    if (!data) return null;
    return (
      <Dialog
        title={"Help from friends & family"}
        buttonText={"Copy link"}
        onClick={handleCopyLink}
        secondaryButtonText={"Not now"}
        autoClose={false}
      >
        <Container fullWidth sx={{gap: "8px", alignItems: "start", height: "160px"}}>
          {isLoading ? (
            <LoadingCenter />
          ) : (
            <ContributionLink url={data.url} handleCopyLink={handleCopyLink} totalBalance={totalBalance} />
          )}
        </Container>
      </Dialog>
    );
  });

export default GenerateContributionLinkDialog;
