import React, {useCallback, useMemo, useRef, useState} from "react";
import {Drawer, IconButton} from "@mui/material";
import {useUserMetaData, useUtils} from "../../../contexts";
import {useGetAccount} from "apis/requests/user/getAccount";
import {Container, CloseDialogButton, LabeledText, IconMenu, Colors} from "bounce-ui/consumer";
import DashboardMenuItems from "./DashboardMenuItems";
import {DisputeItem, HomeItem, LogoutItem, ManageContactItem, SupportItem} from "./menu-items";
import {Pages} from "../../../bi-events/enum";
import ShareContributionLink from "./menu-items/ShareContributionLink";

const SectionTitle = ({title}: {title: string}) => (
  <LabeledText
    size={12}
    fontWeight={"medium"}
    sx={{
      color: (theme) => theme.palette.text.primary,
      backgroundColor: Colors.grey._100,
      width: "100%",
      textTransform: "uppercase",
      padding: "2px 52px",
    }}
  >
    {title}
  </LabeledText>
);

const MainMenu = () => {
  const {pageViewEvent, pageClickEvent, clearCredentials} = useUtils();
  const {loggedIn, userId, isAgent, accountId} = useUserMetaData();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const {data: account} = useGetAccount({accountId}, {enabled: !!accountId});
  const activePaymentPlan = useMemo(() => {
    if (account) return account.active_payment_plan;
  }, [account]);
  const isAccountResolved = activePaymentPlan?.state === "resolved";
  const handleOpen = useCallback((): void => {
    pageClickEvent({page: Pages.MENU, cta: "open_menu"});
    pageViewEvent({page: Pages.MENU});
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback((): void => {
    setOpen(false);
  }, [setOpen]);

  if (!loggedIn) {
    return null;
  }

  return (
    <>
      <IconButton sx={{color: "white"}} ref={anchorRef} onClick={handleOpen}>
        <IconMenu />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={handleClose} sx={{width: 220}}>
        <div role="presentation" onClick={handleClose} onKeyDown={handleClose}>
          <CloseDialogButton onClose={handleClose} />
          <Container width={"360px"} sx={{padding: "58px 0px", gap: "24px"}}>
            <Container fullWidth sx={{gap: "0px"}}>
              <SectionTitle title={"General"} />
              <HomeItem closeHandler={handleClose} />
            </Container>
            {!isAccountResolved && accountId && account && (
              <Container fullWidth sx={{gap: "0px"}}>
                <SectionTitle title={"Payment preferences"} />
                <ShareContributionLink account={account} closeHandler={handleClose} />
                {activePaymentPlan && (
                  <DashboardMenuItems
                    accountId={accountId}
                    activePaymentPlanId={activePaymentPlan.payment_plan_id}
                    closeHandler={handleClose}
                  />
                )}
              </Container>
            )}
            <Container fullWidth sx={{gap: "0px"}}>
              <SectionTitle title={"General Options"} />
              {userId && <ManageContactItem userId={userId} closeHandler={handleClose} />}
              <SupportItem closeHandler={handleClose} />
              {!activePaymentPlan && accountId && <DisputeItem accountId={accountId} closeHandler={handleClose} />}
              {!isAgent && userId && (
                <LogoutItem userId={userId} closeHandler={handleClose} clearCredentials={clearCredentials} />
              )}
            </Container>
          </Container>
        </div>
      </Drawer>
    </>
  );
};

export default MainMenu;
