import styled from "@mui/material/styles/styled";
import {Slider} from "@mui/material";
import {Colors} from "@bounce/shared/ui";

export const PrettySlider = styled(Slider)(({theme}) => ({
  color: Colors.primary._800,
  height: 2,
  "& .MuiSlider-rail": {
    backgroundColor: Colors.grey._200,
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 12,
    width: 12,
    backgroundColor: "currentColor",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 14,
    fontWeight: "bold",
    background: "unset",
    padding: 0,
    width: 46,
    height: 46,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: Colors.primary._800,
    transformOrigin: "bottom left",
    transform: "translate(50%, -85%) rotate(-45deg) scale(0)",
    "&:before": {display: "none"},
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -85%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
}));
