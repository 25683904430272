import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {SearchUserRead} from "@bounce/shared/declarations";

type Variables = {
  searchKey?: string;
  searchType?: string;
};

type Data = Array<SearchUserRead>;

export const searchUsers = async (variables: Variables): Promise<Data> => {
  const result = await axiosFlexInstance.get<Data, AxiosResponse<Data>>("/search", {
    params: {q: variables.searchKey, search_type: variables.searchType},
  });
  return result.data;
};
